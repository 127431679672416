import React, { useState, useEffect } from 'react'
import { Button, Tag, Table, Checkbox, Space, message } from 'antd'
import { useNotifications } from 'hooks'
import { type NOTIFICATION_DT } from 'types'
import { type CheckboxChangeEvent } from 'antd/es/checkbox'
import PageTitle from './PageTitle'

const NotificationsPage: React.FC = () => {
  const { notifications, clearSelectedNotifications, clearAllNotifications } = useNotifications()
  const [selectedIds, setSelectedIds] = useState<number[]>([])
  const handleSelect = (e: CheckboxChangeEvent, id: number) => {
    if (e.target.checked) {
      setSelectedIds(prev => [...prev, id])
    } else {
      setSelectedIds(prev => prev.filter(i => i !== id))
    }
  }
  const columns = [
    {
      title: 'Select',
      dataIndex: 'id',
      render: (_: any, record: NOTIFICATION_DT) => <Checkbox onChange={e => { handleSelect(e, record.id) }} />
    },
    {
      title: 'Type',
      dataIndex: 'type',
      render: (type: string) => <Tag color={getTypeColor(type)}>{type.toUpperCase()}</Tag>
    },
    {
      title: 'Message',
      dataIndex: 'message',
      render: (text: any) => <div style={{ whiteSpace: 'normal' }}>{text}</div>
    },
    {
      title: 'Timestamp',
      dataIndex: 'timestamp',
      render: (timestamp: number) => new Date(timestamp).toLocaleString()
    }
  ]

  const getTypeColor = (type: string) => {
    switch (type) {
      case 'error': return 'red'
      case 'success': return 'green'
      case 'warning': return 'yellow'
      default: return 'blue'
    }
  }

  const handleClearSelected = () => {
    if (selectedIds.length <= 0) {
      void message.error('Select notification to clear')
      return
    }
    clearSelectedNotifications(selectedIds)
  }

  return (
    <div>
      <PageTitle text="Notifications" />
      <Space>
        <Button type="primary" danger onClick={handleClearSelected}>Clear Selected Notifications</Button>
        <Button type="primary" danger onClick={clearAllNotifications}>Clear All Notifications</Button>
      </Space>
      <Table bordered columns={columns} dataSource={notifications} rowKey="id" />
    </div>
  )
}

export default NotificationsPage
