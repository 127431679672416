function generateRandomNumbers (count: number) {
  const randomNumbers = []
  for (let i = 0; i < count; i++) {
    randomNumbers.push(Math.floor(Math.random() * 10))
  }
  return randomNumbers
}

export const frameworkSampleData = {
  assessment_data: {
    title: 'Simulated Test',
    cadence: 'daily',
    pot: 'technical'
  },
  header_summary_data: {
    critical_findings: 1,
    compliance_score: 0.943952802359882,
    risk_exposure: {
      risk_exposure_score: 0.46044776119402986,
      risk_exposure_description: 'LOW'
    },
    compliance_trend: 5,
    risk_exposure_trend: 0.0,
    critical_findings_trend: 0,
    new_findings_results: [],
    remediated_old_findings_results: [],
    new_findings: 2,
    remediation_progress: 0.8,
    new_findings_trend: 0,
    remediation_progress_trend: 5,
    total_passes: 32,
    criticalities: {
      pass_high: 31,
      pass_medium: 1,
      fail_high: 36,
      fail_medium: 267,
      fail_low: 4
    }
  },
  mid_section_summary_report: {
    framework_sum: [
      { date: 1708261797113, passed: 32, failed: 207, total: 239 },
      { date: 1708262714334, passed: 132, failed: 207, total: 339 },
      { date: 1708265190913, passed: 52, failed: 87, total: 139 },
      { date: 1708268772094, passed: 32, failed: 307, total: 339 },
      { date: 1708268882094, passed: 307, failed: 32, total: 339 }
    ],
    critical_findings: [
      { name: 'iam', Critical: 0, High: 2 },
      { name: 'config', Critical: 0, High: 0 },
      { name: 'cloudtrail', Critical: 0, High: 34 },
      { name: 'guardduty', Critical: 0, High: 0 },
      { name: 'securityhub', Critical: 0, High: 0 },
      { name: 'vpc', Critical: 0, High: 0 },
      { name: 'cloudwatch', Critical: 0, High: 0 }
    ],
    new_vs_old_findings: [
      { date: 1708261797113, New: 7, Existing: 5 },
      { date: 1708262714334, New: 9, Existing: 4 },
      { date: 1708268882094, New: 10, Existing: 6 }
    ],
    persistent_findings: [
      { date: 1708261797113, Critical: 4, High: 10, Medium: 20, Low: 30 },
      { date: 1708262714334, Critical: 5, High: 11, Medium: 19, Low: 29 },
      { date: 1708265190913, Critical: 5, High: 12, Medium: 25, Low: 28 },
      { date: 1708268772094, Critical: 6, High: 13, Medium: 22, Low: 27 }
    ],
    predicted_future_findings: [
      { date: 1708261797113, actual: 100, predicted: null },
      { date: 1708262714334, actual: 120, predicted: null },
      { date: 1708265190913, actual: 150, predicted: null },
      { date: 1708268772094, actual: 130, predicted: 130 },
      // Assuming from May onwards, the data is predicted by the AI model
      { date: 1708268882094, actual: null, predicted: 140 }
    ],
    radar_chart_data: [
      {
        domain: 'cc.1',
        current_pass_rate: 0.8888888888888888,
        previous_pass_rate: 0.55,
        fullMark: 1
      },
      { domain: 'cc.2', current_pass_rate: 0.9, previous_pass_rate: 0.55, fullMark: 1 },
      { domain: 'cc.4', current_pass_rate: 0.6, previous_pass_rate: 0.55, fullMark: 1 },
      { domain: 'cc.5', current_pass_rate: 0.9, previous_pass_rate: 0.55, fullMark: 1 },
      {
        domain: 'cc.6',
        current_pass_rate: 0.999,
        previous_pass_rate: 0.55,
        fullMark: 1
      },
      {
        domain: 'cc.7',
        current_pass_rate: 0.779816513761468,
        previous_pass_rate: 0.55,
        fullMark: 1
      },
      { domain: 'cc.8', current_pass_rate: 0.99, previous_pass_rate: 0.55, fullMark: 1 }
    ],
    raw_prediction_data: {
      predicted_value: 'Based on the provided content, the total count of failed checks is predicted to be 521.',
      valid_predicted_value: false
    }
  },
  bottom_section_summary_report: [
    {
      row_key: '782327506192',
      Domain: 'cc.1.3',
      Expectation:
        'Ensure IAM AWS-Managed policies that allow full "*:*" administrative privileges are not attached',
      Evaluation: 'PASS',
      AccountId: generateRandomNumbers(12),
      ResourceArn:
        'arn:aws:iam::aws:policy/aws-service-role/AWSSupportServiceRolePolicy',
      Region: 'us-east-1',
      ServiceName: 'iam',
      Severity: 'high',
      Risk: 'IAM policies are the means by which privileges are granted to users; groups; or roles. It is recommended and considered a standard security advice to grant least privilege\u2014that is; granting only the permissions required to perform a task. Determine what users need to do and then craft policies for them that let the users perform only those tasks instead of allowing full administrative privileges. Providing full administrative privileges instead of restricting to the minimum set of permissions that the user is required to do exposes the resources to potentially unwanted actions.',
      ResourceId: 'AWSSupportServiceRolePolicy',
      RecommendationText:
        'It is more secure to start with a minimum set of permissions and grant additional permissions as necessary; rather than starting with permissions that are too lenient and then trying to tighten them later. List policies an analyze if permissions are the least possible to conduct business activities.',
      RecommendationUrl:
        'http://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html'
    },
    {
      row_key: '419871584521',
      Domain: 'cc.1.3',
      Expectation:
        'Ensure IAM AWS-Managed policies that allow full "*:*" administrative privileges are not attached',
      Evaluation: 'PASS',
      AccountId: generateRandomNumbers(12),
      ResourceArn:
        'arn:aws:iam::aws:policy/aws-service-role/AWSTrustedAdvisorServiceRolePolicy',
      Region: 'us-east-1',
      ServiceName: 'iam',
      Severity: 'high',
      Risk: 'IAM policies are the means by which privileges are granted to users; groups; or roles. It is recommended and considered a standard security advice to grant least privilege\u2014that is; granting only the permissions required to perform a task. Determine what users need to do and then craft policies for them that let the users perform only those tasks instead of allowing full administrative privileges. Providing full administrative privileges instead of restricting to the minimum set of permissions that the user is required to do exposes the resources to potentially unwanted actions.',
      ResourceId: 'AWSTrustedAdvisorServiceRolePolicy',
      RecommendationText:
        'It is more secure to start with a minimum set of permissions and grant additional permissions as necessary; rather than starting with permissions that are too lenient and then trying to tighten them later. List policies an analyze if permissions are the least possible to conduct business activities.',
      RecommendationUrl:
        'http://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html'
    },
    {
      row_key: '160531593547',
      Domain: 'cc.1.3',
      Expectation:
        'Ensure inline policies that allow full "*:*" administrative privileges are not associated to IAM identities',
      Evaluation: 'PASS',
      AccountId: generateRandomNumbers(12),
      ResourceArn:
        'arn:aws:iam::603633971584:role/aws-reserved/sso.amazonaws.com/AWSReservedSSO_SecretsVaultAccess_ca0dafd82d978cee',
      Region: 'us-east-1',
      ServiceName: 'iam',
      Severity: 'high',
      Risk: 'IAM policies are the means by which privileges are granted to users, groups or roles. It is recommended and considered a standard security advice to grant least privilege\u2014that is; granting only the permissions required to perform a task. Determine what users need to do and then craft policies for them that let the users perform only those tasks instead of allowing full administrative privileges. Providing full administrative privileges instead of restricting to the minimum set of permissions that the user is required to do exposes the resources to potentially unwanted actions.',
      ResourceId:
        'AWSReservedSSO_SecretsVaultAccess_ca0dafd82d978cee/AwsSSOInlinePolicy',
      RecommendationText:
        'It is more secure to start with a minimum set of permissions and grant additional permissions as necessary; rather than starting with permissions that are too lenient and then trying to tighten them later. List policies an analyze if permissions are the least possible to conduct business activities.',
      RecommendationUrl:
        'http://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html'
    },
    {
      row_key: '108974512227',
      Domain: 'cc.1.3',
      Expectation: 'Ensure User Access Keys unused are disabled',
      Evaluation: 'PASS',
      AccountId: generateRandomNumbers(12),
      ResourceArn: 'arn:aws:iam::603633971584:root',
      Region: 'us-east-1',
      ServiceName: 'iam',
      Severity: 'medium',
      Risk: 'To increase the security of your AWS account; remove IAM user credentials (that is; passwords and access keys) that are not needed. For example; when users leave your organization or no longer need AWS access.',
      ResourceId: '<root_account>',
      RecommendationText:
        'Find the credentials that they were using and ensure that they are no longer operational. Ideally; you delete credentials if they are no longer needed. You can always recreate them at a later date if the need arises. At the very least; you should change the password or deactivate the access keys so that the former users no longer have access.',
      RecommendationUrl:
        'https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_finding-unused.html'
    },
    {
      row_key: '065402572141',
      Domain: 'cc.1.3',
      Expectation:
        'Ensure IAM AWS-Managed policies that allow full "*:*" administrative privileges are not attached',
      Evaluation: 'PASS',
      AccountId: generateRandomNumbers(12),
      ResourceArn:
        'arn:aws:iam::aws:policy/aws-service-role/AWSSSOServiceRolePolicy',
      Region: 'us-east-1',
      ServiceName: 'iam',
      Severity: 'high',
      Risk: 'IAM policies are the means by which privileges are granted to users; groups; or roles. It is recommended and considered a standard security advice to grant least privilege\u2014that is; granting only the permissions required to perform a task. Determine what users need to do and then craft policies for them that let the users perform only those tasks instead of allowing full administrative privileges. Providing full administrative privileges instead of restricting to the minimum set of permissions that the user is required to do exposes the resources to potentially unwanted actions.',
      ResourceId: 'AWSSSOServiceRolePolicy',
      RecommendationText:
        'It is more secure to start with a minimum set of permissions and grant additional permissions as necessary; rather than starting with permissions that are too lenient and then trying to tighten them later. List policies an analyze if permissions are the least possible to conduct business activities.',
      RecommendationUrl:
        'http://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html'
    },
    {
      row_key: '992030451289',
      Domain: 'cc.1.3',
      Expectation:
        'Ensure IAM AWS-Managed policies that allow full "*:*" administrative privileges are not attached',
      Evaluation: 'PASS',
      AccountId: generateRandomNumbers(12),
      ResourceArn: 'arn:aws:iam::aws:policy/job-function/ViewOnlyAccess',
      Region: 'us-east-1',
      ServiceName: 'iam',
      Severity: 'high',
      Risk: 'IAM policies are the means by which privileges are granted to users; groups; or roles. It is recommended and considered a standard security advice to grant least privilege\u2014that is; granting only the permissions required to perform a task. Determine what users need to do and then craft policies for them that let the users perform only those tasks instead of allowing full administrative privileges. Providing full administrative privileges instead of restricting to the minimum set of permissions that the user is required to do exposes the resources to potentially unwanted actions.',
      ResourceId: 'ViewOnlyAccess',
      RecommendationText:
        'It is more secure to start with a minimum set of permissions and grant additional permissions as necessary; rather than starting with permissions that are too lenient and then trying to tighten them later. List policies an analyze if permissions are the least possible to conduct business activities.',
      RecommendationUrl:
        'http://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html'
    },
    {
      row_key: '517297838390',
      Domain: 'cc.1.3',
      Expectation:
        'Ensure IAM AWS-Managed policies that allow full "*:*" administrative privileges are not attached',
      Evaluation: 'PASS',
      AccountId: generateRandomNumbers(12),
      ResourceArn:
        'arn:aws:iam::aws:policy/aws-service-role/AWSOrganizationsServiceTrustPolicy',
      Region: 'us-east-1',
      ServiceName: 'iam',
      Severity: 'high',
      Risk: 'IAM policies are the means by which privileges are granted to users; groups; or roles. It is recommended and considered a standard security advice to grant least privilege\u2014that is; granting only the permissions required to perform a task. Determine what users need to do and then craft policies for them that let the users perform only those tasks instead of allowing full administrative privileges. Providing full administrative privileges instead of restricting to the minimum set of permissions that the user is required to do exposes the resources to potentially unwanted actions.',
      ResourceId: 'AWSOrganizationsServiceTrustPolicy',
      RecommendationText:
        'It is more secure to start with a minimum set of permissions and grant additional permissions as necessary; rather than starting with permissions that are too lenient and then trying to tighten them later. List policies an analyze if permissions are the least possible to conduct business activities.',
      RecommendationUrl:
        'http://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html'
    },
    {
      row_key: '277628694100',
      Domain: 'cc.1.3',
      Expectation:
        'Ensure IAM AWS-Managed policies that allow full "*:*" administrative privileges are not attached',
      Evaluation: 'FAIL',
      AccountId: generateRandomNumbers(12),
      ResourceArn: 'arn:aws:iam::aws:policy/AdministratorAccess',
      Region: 'us-east-1',
      ServiceName: 'iam',
      Severity: 'high',
      Risk: 'IAM policies are the means by which privileges are granted to users; groups; or roles. It is recommended and considered a standard security advice to grant least privilege\u2014that is; granting only the permissions required to perform a task. Determine what users need to do and then craft policies for them that let the users perform only those tasks instead of allowing full administrative privileges. Providing full administrative privileges instead of restricting to the minimum set of permissions that the user is required to do exposes the resources to potentially unwanted actions.',
      ResourceId: 'AdministratorAccess',
      RecommendationText:
        'It is more secure to start with a minimum set of permissions and grant additional permissions as necessary; rather than starting with permissions that are too lenient and then trying to tighten them later. List policies an analyze if permissions are the least possible to conduct business activities.',
      RecommendationUrl:
        'http://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html'
    },
    {
      row_key: '629737064398',
      Domain: 'cc.1.3',
      Expectation:
        'Ensure IAM AWS-Managed policies that allow full "*:*" administrative privileges are not attached',
      Evaluation: 'PASS',
      AccountId: generateRandomNumbers(12),
      ResourceArn: 'arn:aws:iam::aws:policy/SecurityAudit',
      Region: 'us-east-1',
      ServiceName: 'iam',
      Severity: 'high',
      Risk: 'IAM policies are the means by which privileges are granted to users; groups; or roles. It is recommended and considered a standard security advice to grant least privilege\u2014that is; granting only the permissions required to perform a task. Determine what users need to do and then craft policies for them that let the users perform only those tasks instead of allowing full administrative privileges. Providing full administrative privileges instead of restricting to the minimum set of permissions that the user is required to do exposes the resources to potentially unwanted actions.',
      ResourceId: 'SecurityAudit',
      RecommendationText:
        'It is more secure to start with a minimum set of permissions and grant additional permissions as necessary; rather than starting with permissions that are too lenient and then trying to tighten them later. List policies an analyze if permissions are the least possible to conduct business activities.',
      RecommendationUrl:
        'http://docs.aws.amazon.com/IAM/latest/UserGuide/best-practices.html'
    },
    {
      row_key: '367511593753',
      Domain: 'cc.2.1',
      Expectation: 'Ensure AWS Config is enabled in all regions.',
      Evaluation: 'FAIL',
      AccountId: generateRandomNumbers(12),
      ResourceArn: 'arn:aws:iam::603633971584:root',
      Region: 'ap-northeast-3',
      ServiceName: 'config',
      Severity: 'medium',
      Risk: 'The AWS configuration item history captured by AWS Config enables security analysis, resource change tracking and compliance auditing.',
      ResourceId: '603633971584',
      RecommendationText:
        'It is recommended to enable AWS Config in all regions.',
      RecommendationUrl:
        'https://aws.amazon.com/blogs/mt/aws-config-best-practices/'
    },
    {
      row_key: '489207974989',
      Domain: 'cc.2.1',
      Expectation:
        'Ensure that all your AWS CloudTrail trails are configured to log Data events in order to record S3 object-level API operations, such as GetObject, DeleteObject and PutObject, for individual S3 buckets or for all current and future S3 buckets provisioned in your AWS account.',
      Evaluation: 'FAIL',
      AccountId: generateRandomNumbers(12),
      ResourceArn: 'arn:aws:iam::603633971584:root',
      Region: 'us-east-1',
      ServiceName: 'cloudtrail',
      Severity: 'low',
      Risk: 'If logs are not enabled, monitoring of service use and threat analysis is not possible.',
      ResourceId: '603633971584',
      RecommendationText:
        'Enable logs. Create an S3 lifecycle policy. Define use cases, metrics and automated responses where applicable.',
      RecommendationUrl:
        'https://docs.aws.amazon.com/AmazonS3/latest/userguide/enable-cloudtrail-logging-for-s3.html'
    },
    {
      row_key: '246248510893',
      Domain: 'cc.2.1',
      Expectation: 'Ensure CloudTrail is enabled in all regions',
      Evaluation: 'FAIL',
      AccountId: generateRandomNumbers(12),
      ResourceArn: 'arn:aws:iam::603633971584:root',
      Region: 'eu-central-1',
      ServiceName: 'cloudtrail',
      Severity: 'high',
      Risk: 'AWS CloudTrail is a web service that records AWS API calls for your account and delivers log files to you. The recorded information includes the identity of the API caller; the time of the API call; the source IP address of the API caller; the request parameters; and the response elements returned by the AWS service.',
      ResourceId: '603633971584',
      RecommendationText:
        'Ensure Logging is set to ON on all regions (even if they are not being used at the moment.',
      RecommendationUrl:
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/cloudtrailconcepts.html#cloudtrail-concepts-management-events'
    },
    {
      row_key: '200555611699',
      Domain: 'cc.2.1',
      Expectation: 'Ensure AWS Config is enabled in all regions.',
      Evaluation: 'FAIL',
      AccountId: '172109716512',
      ResourceArn: 'arn:aws:iam::603633971584:root',
      Region: 'eu-west-3',
      ServiceName: 'config',
      Severity: 'medium',
      Risk: 'The AWS configuration item history captured by AWS Config enables security analysis, resource change tracking and compliance auditing.',
      ResourceId: '603633971584',
      RecommendationText:
        'It is recommended to enable AWS Config in all regions.',
      RecommendationUrl:
        'https://aws.amazon.com/blogs/mt/aws-config-best-practices/'
    },
    {
      row_key: '803060525730',
      Domain: 'cc.2.1',
      Expectation: 'Ensure AWS Config is enabled in all regions.',
      Evaluation: 'PASS',
      AccountId: '172109716512',
      ResourceArn: 'arn:aws:iam::603633971584:root',
      Region: 'eu-west-3',
      ServiceName: 'config',
      Severity: 'medium',
      Risk: 'The AWS configuration item history captured by AWS Config enables security analysis, resource change tracking and compliance auditing.',
      ResourceId: '603633971584',
      RecommendationText:
        'It is recommended to enable AWS Config in all regions.',
      RecommendationUrl:
        'https://aws.amazon.com/blogs/mt/aws-config-best-practices/'
    },
    {
      row_key: '916483733879',
      Domain: 'cc.2.1',
      Expectation: 'Ensure CloudTrail is enabled in all regions',
      Evaluation: 'FAIL',
      AccountId: generateRandomNumbers(12),
      ResourceArn: 'arn:aws:iam::603633971584:root',
      Region: 'eu-central-1',
      ServiceName: 'cloudtrail',
      Severity: 'high',
      Risk: 'AWS CloudTrail is a web service that records AWS API calls for your account and delivers log files to you. The recorded information includes the identity of the API caller; the time of the API call; the source IP address of the API caller; the request parameters; and the response elements returned by the AWS service.',
      ResourceId: '603633971584',
      RecommendationText:
        'Ensure Logging is set to ON on all regions (even if they are not being used at the moment.',
      RecommendationUrl:
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/cloudtrailconcepts.html#cloudtrail-concepts-management-events'
    },
    {
      row_key: '810060244557',
      Domain: 'cc.2.1',
      Expectation: 'Ensure CloudTrail is enabled in all regions',
      Evaluation: 'PASS',
      AccountId: generateRandomNumbers(12),
      ResourceArn: 'arn:aws:iam::603633971584:root',
      Region: 'eu-central-1',
      ServiceName: 'cloudtrail',
      Severity: 'high',
      Risk: 'AWS CloudTrail is a web service that records AWS API calls for your account and delivers log files to you. The recorded information includes the identity of the API caller; the time of the API call; the source IP address of the API caller; the request parameters; and the response elements returned by the AWS service.',
      ResourceId: '603633971584',
      RecommendationText:
        'Ensure Logging is set to ON on all regions (even if they are not being used at the moment.',
      RecommendationUrl:
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/cloudtrailconcepts.html#cloudtrail-concepts-management-events'
    },
    {
      row_key: '432719397673',
      Domain: 'cc.2.1',
      Expectation: 'Ensure CloudTrail is enabled in all regions',
      Evaluation: 'PASS',
      AccountId: generateRandomNumbers(12),
      ResourceArn: 'arn:aws:iam::603633971584:root',
      Region: 'eu-central-1',
      ServiceName: 'cloudtrail',
      Severity: 'high',
      Risk: 'AWS CloudTrail is a web service that records AWS API calls for your account and delivers log files to you. The recorded information includes the identity of the API caller; the time of the API call; the source IP address of the API caller; the request parameters; and the response elements returned by the AWS service.',
      ResourceId: '603633971584',
      RecommendationText:
        'Ensure Logging is set to ON on all regions (even if they are not being used at the moment.',
      RecommendationUrl:
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/cloudtrailconcepts.html#cloudtrail-concepts-management-events'
    },
    {
      row_key: '359793441399',
      Domain: 'cc.2.1',
      Expectation: 'Ensure CloudTrail is enabled in all regions',
      Evaluation: 'PASS',
      AccountId: generateRandomNumbers(12),
      ResourceArn: 'arn:aws:iam::603633971584:root',
      Region: 'eu-central-1',
      ServiceName: 'cloudtrail',
      Severity: 'high',
      Risk: 'AWS CloudTrail is a web service that records AWS API calls for your account and delivers log files to you. The recorded information includes the identity of the API caller; the time of the API call; the source IP address of the API caller; the request parameters; and the response elements returned by the AWS service.',
      ResourceId: '603633971584',
      RecommendationText:
        'Ensure Logging is set to ON on all regions (even if they are not being used at the moment.',
      RecommendationUrl:
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/cloudtrailconcepts.html#cloudtrail-concepts-management-events'
    },
    {
      row_key: '299184357139',
      Domain: 'cc.2.1',
      Expectation: 'Ensure CloudTrail is enabled in all regions',
      Evaluation: 'PASS',
      AccountId: generateRandomNumbers(12),
      ResourceArn: 'arn:aws:iam::603633971584:root',
      Region: 'eu-central-1',
      ServiceName: 'cloudtrail',
      Severity: 'high',
      Risk: 'AWS CloudTrail is a web service that records AWS API calls for your account and delivers log files to you. The recorded information includes the identity of the API caller; the time of the API call; the source IP address of the API caller; the request parameters; and the response elements returned by the AWS service.',
      ResourceId: '603633971584',
      RecommendationText:
        'Ensure Logging is set to ON on all regions (even if they are not being used at the moment.',
      RecommendationUrl:
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/cloudtrailconcepts.html#cloudtrail-concepts-management-events'
    },
    {
      row_key: '928478267933',
      Domain: 'cc.2.1',
      Expectation: 'Ensure CloudTrail is enabled in all regions',
      Evaluation: 'PASS',
      AccountId: generateRandomNumbers(12),
      ResourceArn: 'arn:aws:iam::603633971584:root',
      Region: 'eu-central-1',
      ServiceName: 'cloudtrail',
      Severity: 'high',
      Risk: 'AWS CloudTrail is a web service that records AWS API calls for your account and delivers log files to you. The recorded information includes the identity of the API caller; the time of the API call; the source IP address of the API caller; the request parameters; and the response elements returned by the AWS service.',
      ResourceId: '603633971584',
      RecommendationText:
        'Ensure Logging is set to ON on all regions (even if they are not being used at the moment.',
      RecommendationUrl:
        'https://docs.aws.amazon.com/awscloudtrail/latest/userguide/cloudtrailconcepts.html#cloudtrail-concepts-management-events'
    }
  ]
}
