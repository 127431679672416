import React, { useContext, useState } from 'react'
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Badge,
  Button as MButton
} from '@mui/material'
import NotificationsIcon from '@mui/icons-material/Notifications'
import { UserContext } from 'context'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'config'
import { ArrowBackIosNewOutlined } from '@mui/icons-material'
import { AppShell, Burger, Group, Skeleton, Button, Modal, Text, Title } from '@mantine/core'
import { useDisclosure } from '@mantine/hooks'
import './navbar.css'
import Logo from '../../../assets/logo/favicon-black.png'
import { Tag } from 'antd'

interface NavBarProps {
  title: string
  onDemandFunction: () => Promise<void>
  mainContent: any
}

const NavBar: React.FC<NavBarProps> = ({ title, onDemandFunction, mainContent }) => {
  const navigate = useNavigate()
  const { state } = useContext(UserContext)
  const { user } = state
  const [isModalOpen, setIsModalOpen] = useState(false) // State to control modal visibility
  const [opened, { toggle }] = useDisclosure()

  const handleNavigation = () => {
    navigate(ROUTES.OVERVIEW)
  }

  const handleOnDemandClick = () => {
    setIsModalOpen(true) // Show the modal when the button is clicked
  }

  const confirmOnDemand = async () => {
    await onDemandFunction() // Execute the function if confirmed
    setIsModalOpen(false) // Close the modal
    navigate(ROUTES.OVERVIEW)
  }
  const isMobile = window.innerWidth <= 600

  return (
    <>
    <AppShell
      header={{ height: 60 }}
      navbar={{ width: 300, breakpoint: 'sm', collapsed: { mobile: !opened, desktop: !opened } }}
      padding="md"
    >
      <AppShell.Header style={{ paddingInline: '24px' }}>
        <Group h="100%" px="md">
          <Burger opened={opened} onClick={toggle} hiddenFrom="sm" size="sm" />
          <Typography style={{ display: 'flex', alignItems: 'center' }} variant="h6" component="div" sx={{ flexGrow: 1, textAlign: 'start' }}>
            <img width={45} src={Logo} alt='logo' />  <Tag style={{ marginInline: '20px' }}>
              <Title order={6}>{title}</Title>
            </Tag>
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={user?.notifications ? user.notifications.length : 0} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <IconButton color="inherit" onClick={handleNavigation}>
              <ArrowBackIosNewOutlined />
          </IconButton>
          <MButton className='hidethis' sx={{ textTransform: 'none' }} color="inherit">{`${user?.given_name} ${user?.family_name}`}</MButton>
          <MButton className='hidethis' onClick={handleOnDemandClick} variant="contained" color="warning">On-Demand Scan</MButton>
          </Group>
      </AppShell.Header>
      <AppShell.Navbar p="md">
      <MButton sx={{ textTransform: 'none' }} color="inherit">{`${user?.given_name} ${user?.family_name}`}</MButton>
        <MButton onClick={handleOnDemandClick} variant="contained" color="warning">On-Demand Scan</MButton>
      </AppShell.Navbar>
      <AppShell.Main>
       {mainContent}
      </AppShell.Main>
    </AppShell>
      <Modal
        opened={isModalOpen}
        onClose={() => { setIsModalOpen(false) }}
        title="Confirm On-Demand Scan"
      >
        <Text>Are you sure you want to run the on-demand scan? It will put the current assessment &apos;Under Evaluation&apos; and will not be accessible while it runs.</Text>
        <Button style={{ marginRight: '5px' }} color="green" onClick={confirmOnDemand}>Yes</Button>
        <Button style={{ marginLeft: '5px' }} color="red" onClick={() => { setIsModalOpen(false) }}>No</Button>
      </Modal>
    </>
  )
}
export { NavBar }
