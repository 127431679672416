import React from 'react'
import {
  AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts'
import { type PersistentFindingsDT } from 'types'

interface TooltipDataItem {
  name: string
  value: string | number
  color?: string
}

interface CustomTooltipProps {
  active?: boolean
  payload?: TooltipDataItem[]
  label?: string | number
}

const formatDate = (unixTimestamp: string | number | Date) => {
  const date = new Date(unixTimestamp)
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  })
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload?.length) {
    return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
          <p className="label">{`Date: ${formatDate(Number(label))}`}</p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>{`${entry.name}: ${entry.value}`}</p>
          ))}
        </div>
    )
  }

  return null
}

interface PersistentFindingsProps {
  data: PersistentFindingsDT[]
}
const PersistentFindings: React.FC<PersistentFindingsProps> = ({ data }) => {
  return (
    <AreaChart
      width={600}
      height={400}
      data={data}
      margin={{
        top: 10, right: 30, left: 0, bottom: 0
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="date" tickFormatter={formatDate} />
      <YAxis />
      <Tooltip content={<CustomTooltip />} />
      <Legend />
      <Area type="monotone" dataKey="Critical" stackId="1" stroke="#d9534f" fill="#d9534f" />
      <Area type="monotone" dataKey="High" stackId="1" stroke="#f0ad4e" fill="#f0ad4e" />
      <Area type="monotone" dataKey="Medium" stackId="1" stroke="#5bc0de" fill="#5bc0de" />
      <Area type="monotone" dataKey="Low" stackId="1" stroke="#5cb85c" fill="#5cb85c" />
    </AreaChart>
  )
}

export { PersistentFindings }
