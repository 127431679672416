import { fetchAuthSession } from '@aws-amplify/auth'

const getIdToken = async (): Promise<string | undefined> => {
  try {
    const token = (await fetchAuthSession()).tokens
    return token?.idToken?.toString()
  } catch (error) {
    console.error('Error getting the token', error)
    return undefined
  }
}

export { getIdToken }
