import React, { useState } from 'react'
import { Form, Button, Row, Col, Input, Space, Checkbox, Modal, message } from 'antd'
import { useNavigate } from 'react-router-dom'
import { useNotifications } from 'hooks'
import { ROUTES } from 'config'
import { createGdprAssessment } from 'services'
const GDPRScopingForm = () => {
  const { addNotification } = useNotifications()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const onFinish = async (values: any) => {
    console.log('Received values:', values)
    Modal.confirm({
      title: 'This is going to create the assessment',
      content: 'Do you want to proceed?',
      onOk: async () => {
        try {
          setLoading(true)
          const response = await createGdprAssessment(values)
          if (response?.assessment_id) {
            navigate(`${ROUTES.ASSESSMENTS}/${response?.assessment_id}`)
          }

          void message.success(response?.message)
        } catch (error: any) {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
          void message.error(
            error.message)
        } finally {
          setLoading(false)
        }
      },
      okText: 'Yes',
      cancelText: 'No, Go Back',
      okButtonProps: {
        disabled: loading
      },
      cancelButtonProps: {
        disabled: loading
      }
    })
  }

  return (
    <div className="scoping-form">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <h2>Scope the GDPR Principles, Rights, and Obligations to be Assessed</h2>
        <Form.Item
          name="title"
          label="Title for the Assessment"
          rules={[{ required: true, message: 'Please input the title for the assessment!' }]}
        >
          <Input placeholder="Enter assessment title" />
        </Form.Item>

        {/* <Form.Item label="Select GDPR Areas in Scope">
          <Space direction="vertical" size="large">
            {gdprScope.map((item) => (
              <Row key={item.value} gutter={16} align="middle">
                <Col span={24}>
                  <Form.Item
                    name={['gdpr', item.value, 'inScope']}
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox>{item.text}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            ))}
          </Space>
        </Form.Item> */}

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create Assessment
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default GDPRScopingForm
