import React from 'react'
import { type MidSectionSummaryReport } from 'types'
import { Paper } from '@mantine/core'
import './ComplianceDashboardMidSection.css'

import { AssessmentChart, MidSectionChartOptions } from './midSectionCharts'

interface ComplianceDashboardMidSectionProps {
  data: MidSectionSummaryReport
}

const ComplianceDashboardMidSection: React.FC<
ComplianceDashboardMidSectionProps
> = ({ data }) => {
  return (
    <div
      className='mobile-view'
      style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between' }}>
      <Paper withBorder className='chart1' style={{ backgroundColor: '#fff', width: '62%', borderRadius: '8px', padding: '30px' }}>
      <AssessmentChart data={data.framework_sum} />
      </Paper>

      <Paper withBorder className='chart2' style={{ backgroundColor: '#fff', width: '37%', borderRadius: '8px', padding: '10px' }}>
          <MidSectionChartOptions
          criticalFindings={data.critical_findings}
          newFindings={data.new_vs_old_findings}
          persistentFindings={data.persistent_findings}
          predictedFutureFindings={data.predicted_future_findings}
          radarChartData={data.radar_chart_data}
          rawPredictionData={data.raw_prediction_data}/>
      </Paper>
    </div>
  )
}

export { ComplianceDashboardMidSection }
