/* eslint-disable react/prop-types */
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts'
import { type CriticalFindingsDT } from 'types'

const CustomizedDot = (props: { cx: any, cy: any, value: any }) => {
  const { cx, cy, value } = props

  if (value > 10) {
    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
        <path d="M512 0C229.2 0 0 229.2 0 512s229.2 512 512 512 512-229.2 512-512S794.8 0 512 0z m0 928C283.2 928 96 740.8 96 512S283.2 96 512 96s416 187.2 416 416-187.2 416-416 416z" />
        <path d="M512 288c-17.6 0-32 14.4-32 32v256c0 17.6 14.4 32 32 32s32-14.4 32-32V320c0-17.6-14.4-32-32-32z" />
        <path d="M512 672c-17.6 0-32 14.4-32 32s14.4 32 32 32 32-14.4 32-32-14.4-32-32-32z" />
      </svg>
    )
  }

  return null
}
interface CriticalFindingsProps {
  data: CriticalFindingsDT[]
}
const CriticalFindings: React.FC<CriticalFindingsProps> = ({ data }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <BarChart style={{ width: '100%' }} width={700} height={350} data={data}
            margin={{ top: 20, right: 10, left: 10, bottom: 5 }}>
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis dataKey="name" />
      <YAxis />
      <Tooltip />
      <Legend />
      <Bar dataKey="Critical" fill="#d9534f" minPointSize={5}>
        {/* Customize dot for critical findings */}
        {data.map((entry, index) => (
          <CustomizedDot key={`dot-${index}`} value={entry.Critical} cx={undefined} cy={undefined} />
        ))}
      </Bar>
      <Bar dataKey="High" fill="#f0ad4e" />
    </BarChart>
    </div>
  )
}

export { CriticalFindings }
