// import { socExec } from '../../data/soc_exec'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import {
  Descriptions,
  Badge,
  Button,
  Card,
  Tag,
  Spin,
  List,
  Typography,
  Collapse,
  Space,
  Divider
} from 'antd'
import PageTitle from 'components/core/Utils/PageTitle'
import { useAssessmentDetails } from 'hooks'
import StepByStepDialog from 'components/core/Utils/StepByStepDialog'
import { v4 as uuidv4 } from 'uuid'
import { frameworkDetector, timestampDisplay } from 'utils'
const { Panel } = Collapse

const { Title } = Typography

const AssessmentDetails: React.FC = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false)
  const [clientRoleArn, setClientRoleArn] = useState<string>('')
  const { id } = useParams()

  const {
    getStatusBadgeStatus,
    statusData,
    techScans,
    loadingStates,
    buttonItems,
    handleUploadRoleArn
  } = useAssessmentDetails(id)

  const handleArnHookFunction = async (arn: string) => {
    // Implement what should happen with the ARN here
    console.log('ARN submitted:', arn)
    await handleUploadRoleArn(arn)
  }
  if (loadingStates.loading) {
    return (
      <>
        <Spin style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }} />
      </>
    )
  }

  return (
    <div style={{ paddingLeft: '24px', paddingRight: '24px' }}>
      <PageTitle
        text={statusData.title ?? 'SOC 2 Type II Assessment'}
      />
       <Descriptions bordered column={1} className="descriptions-custom">
       <Descriptions.Item label="Framework">
            <Tag key={frameworkDetector(statusData.assessment_type)} color={'blue'}>
                      {frameworkDetector(statusData.assessment_type).toUpperCase()}
                    </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Status">
            <Badge
              status={getStatusBadgeStatus(statusData.status)}
              text={statusData.status}
            />
          </Descriptions.Item>
          {statusData?.created_on && <Descriptions.Item label="Created On">
            {timestampDisplay(statusData.created_on)}
          </Descriptions.Item>}
          {statusData?.last_modified_on && <Descriptions.Item label="Updated On">
            {timestampDisplay(statusData.last_modified_on)}
          </Descriptions.Item>}
          <Descriptions.Item label="Owner">
            {statusData.owner_name}
          </Descriptions.Item>
          {statusData.scoped_items && (
            <Descriptions.Item label="Scoped TSCs">
              {statusData?.scoped_items?.length > 0 && (
                <span>
                  {statusData.scoped_items.map((tscItem: string) => (
                    <Tag key={tscItem} color={'blue'}>
                      {tscItem.toUpperCase()}
                    </Tag>
                  ))}
                </span>
              )}
            </Descriptions.Item>
          )}
          {statusData.pot && (
            <Descriptions.Item label="Coverage (Policy Review | Technical Scan)">
              {
                <Tag key={uuidv4()} color={'grey'}>
                  {statusData.pot.toUpperCase()}
                </Tag>
              }
            </Descriptions.Item>
          )}
          {/* {evaluations.executive_summary && (
            <Descriptions.Item label="Executive Summary">
              {evaluations.executive_summary}
            </Descriptions.Item>
          )} */}
        </Descriptions>
      <Divider/>
      <Card loading={loadingStates.loading} bordered={false} style={{ marginTop: 24 }}>
        <Card style={{ marginTop: 24 }}>
      </Card>
        {/* Shows when report is ready */}
        {statusData?.status === 'Report Ready' && <Collapse
          bordered={false}
          defaultActiveKey={['1']}
          expandIconPosition="end"
          style={{
            marginTop: '10px',
            border: '1px solid #d9d9d9',
            borderRadius: '10px',
            width: '100%'
          }}
        >
          <Panel
            header={
              <Title level={3} style={{ fontWeight: '200' }}>
                Executive Summary
              </Title>
            }
            key="0"
          >
          </Panel>
        </Collapse>}
        {/* Shows when report is ready */}
         {/* Shows when technical scan is required */}
        {(
          statusData?.pot === 'technical' ||
          statusData?.pot === 'both'
        ) && <Card
          bordered={true}
          style={{
            marginTop: 24,
            border: '1px solid #f0f0f0',
            boxShadow: '0 2px 3px rgba(0, 0, 0, 0.05)'
          }}
        >
          <div style={{ textAlign: 'left', margin: 5 }}>
          <Space>
            {/* <Upload
              onChange={(info) => {
                handleUploadChange(info, 'JSON')
              }}
              disabled={
                // statusData?.pot === 'policy' ||
                !statusData?.pot ||
                statusData?.status === 'Not Started' ||
                statusData?.status === 'Under Evaluation' ||
                statusData?.status === 'Report Ready'
              }
              {...scanProps}
            >

            </Upload>

            <Button
                disabled={
                  // statusData?.pot === 'policy' ||
                  !statusData?.pot ||
                  statusData?.status === 'Not Started' ||
                  statusData?.status === 'Under Evaluation' ||
                  statusData?.status === 'Report Ready' ||
                  statusData?.status === 'Technical Scan In Progress'
                }
                icon={<UploadOutlined />}
              >
                Upload JSON File (Technical Scan)
              </Button> */}
              <StepByStepDialog
                  openInstructionsText={'Connect AWS Account'}
                  disableInstructionButton={!(statusData?.status === 'Not Started' || statusData?.status === 'Active' || !statusData.assessment_version_id)}
                  isVisible={isVisible}
                  loading={loadingStates.start_tech_scan_load}
                  setIsVisible={setIsVisible}
                  clientRoleArn={clientRoleArn}
                  setClientRoleArn={setClientRoleArn}
                  handleArnHookFunction={handleArnHookFunction}
                  disableInstructionText={'Status has to be either Not Started or Active to update role arn. Also, you can only update the role ARN if the assessment has no previous scans'}/>
            </Space>
            {techScans && techScans.length > 0 && (
              <Collapse
                bordered={false}
                defaultActiveKey={['1']}
                expandIconPosition="end"
                style={{
                  marginTop: '10px',
                  border: '1px solid #d9d9d9',
                  borderRadius: '10px',
                  width: '100%'
                }}
              >
                <Panel
                  header={
                    <Title level={3} style={{ fontWeight: '200' }}>
                      Existing Technical Scans
                    </Title>
                  }
                  key="1"
                >
                  <List
                    size="small"
                    bordered
                    dataSource={techScans}
                    renderItem={(item) => <List.Item>{item}</List.Item>}
                  />
                </Panel>
              </Collapse>
            )}
          </div>
        </Card>}
         {/* Shows when when policy review is required */}
        {(
          statusData?.pot === 'policy' || statusData?.pot === 'both'
        ) && <Card
          bordered={true}
          style={{
            marginTop: 24,
            border: '1px solid #f0f0f0',
            boxShadow: '0 2px 3px rgba(0, 0, 0, 0.05)'
          }}
        >
        </Card>}
         {/* Bottom buttons */}
        <div style={{ textAlign: 'center', marginTop: 24 }}>
          <Space>
            {buttonItems.map((buttonItem) => (
              <>
               {/* Shows when enabled */}
                {!buttonItem.disabled && <Button
                  disabled={buttonItem.disabled}
                  onClick={buttonItem.onClick}
                  loading={buttonItem.loading}
                >
                  {buttonItem.label}
                </Button>}
              </>
            ))}
          </Space>
        </div>
      </Card>
    </div>
  )
}

export default AssessmentDetails
