import { createSocAxiosInstance } from 'hooks'
import type { DataStructure } from 'types'
import { domain, resources } from 'config/endpointEnums'
const socAxiosInstance = createSocAxiosInstance()

interface CreatedAssessmentResponse {
  message: string
  assessment_id: string
}
async function createSoc2Assessment (scope: DataStructure): Promise<CreatedAssessmentResponse> {
  const path = `${domain.soc}${resources.createAssessment}`
  const response = await socAxiosInstance.post<CreatedAssessmentResponse>(path, scope)
  console.log(response)
  return response.data
}

export {
  createSoc2Assessment
}
