import React from 'react'
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'
import { type NewVsOldFindingsDT } from 'types'

interface TooltipDataItem {
  name: string
  value: string | number
  color?: string
}

interface CustomTooltipProps {
  active?: boolean
  payload?: TooltipDataItem[]
  label?: string | number
}

const formatDate = (unixTimestamp: string | number | Date) => {
  const date = new Date(unixTimestamp)
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  })
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload?.length) {
    return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
          <p className="label">{`Date: ${formatDate(Number(label))}`}</p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>{`${entry.name}: ${entry.value}`}</p>
          ))}
        </div>
    )
  }

  return null
}

interface EmergingFindingsProps {
  data: NewVsOldFindingsDT[]
}
const EmergingFindings: React.FC<EmergingFindingsProps> = ({ data }) => {
  return (
    <BarChart
      width={600}
      height={300}
      data={data}
      margin={{
        top: 20, right: 30, left: 20, bottom: 5
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
       <XAxis dataKey="date" tickFormatter={formatDate} />
      <YAxis />
      <Tooltip content={<CustomTooltip />} />
      <Legend />
      <Bar dataKey="New" stackId="a" fill="#82ca9d" name="New Findings" />
      <Bar dataKey="Existing" stackId="a" fill="#8884d8" name="Existing Findings" />
    </BarChart>
  )
}

export { EmergingFindings }
