import { createFedRampModAxiosInstance } from 'hooks'
import type {
  FedRampModDataStructure
} from 'types'
import { domain, resources } from 'config/endpointEnums'

const fedRampModAxiosInstance = createFedRampModAxiosInstance()

interface CreatedAssessmentResponse {
  message: string
  assessment_id: string
}
async function createFedRampModAssessment (scope: FedRampModDataStructure): Promise<CreatedAssessmentResponse> {
  const path = `${domain.fedRampMod}${resources.createAssessment}`
  const response = await fedRampModAxiosInstance.post<CreatedAssessmentResponse>(path, scope)
  console.log(response)
  return response.data
}

export {
  createFedRampModAssessment
}
