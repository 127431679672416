export const ROUTES = {
  HOME: '/',
  OVERVIEW: '/overview',
  LANDING_PAGE: '/home',
  LOGIN: '/login',
  REGISTER: '/register',
  VERIFY_EMAIL: '/verify-email',
  RESET_PASSWORD: '/reset-password',
  ADMIN: '/admin',
  DASHBOARD: '/dashboards',
  ASSESSMENTS: '/assessments',
  REPORT: '/report/:report_id',
  NOTFOUND: '*',
  ERROR: '/error',
  ABOUT: '/about-us',
  ABOUT_SOC: '/about-soc',
  ABOUT_AWS: '/about-aws',
  ABOUT_STATE_RAMP: '/about-state-ramp',
  DEMO: '/request-a-demo',
  SOC: '/soc',
  SOC2: '/soc2',
  AWS: '/aws',
  STATE_RAMP: '/state-ramp',
  CONTACT_US: '/contact-us',
  SUBSCRIPTION: '/subscription'
}
