import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'
import {
  deleteAssessment,
  getAssessment,
  pauseAssessment,
  restartAssessment,
  startAssessment,
  uploadTechScanRoleArn
} from 'services'
import type {
  assessment_evaluations,
  AssessmentType
} from 'types'
import { useNotifications } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'config'
interface UseAssessmentDetailsReturn {
  getStatusBadgeStatus: (
    status: string
  ) => 'processing' | 'success' | 'error' | 'default' | 'warning'
  handleUploadRoleArn: (arn: string) => Promise<void>
  statusData: AssessmentType
  policies: string[]
  techScans: string[]
  evaluations: assessment_evaluations
  loadingStates: Record<string, boolean>
  buttonItems: any[]
}

const useAssessmentDetails = (
  id: string | undefined
): UseAssessmentDetailsReturn => {
  const navigate = useNavigate()
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(
    {}
  )
  const { addNotification } = useNotifications()
  const [policies, setPolicies] = useState<string[]>([])
  const [techScans, setTechScans] = useState<string[]>([])
  const [statusData, setStatusData] = useState<AssessmentType>(
    {} as unknown as AssessmentType
  )
  const [evaluations, setEvaluations] = useState<assessment_evaluations>(
    {} as unknown as assessment_evaluations
  )

  const fetchAssessmentData = useCallback(async () => {
    try {
      if (!id) {
        void message.error('Assessment ID is required')
        return
      }
      setLoadingStates((prev) => ({ ...prev, loading: true }))
      const param = { assessment_id: id }
      const rAssessment = await getAssessment(param)
      setStatusData(rAssessment.status_data)
      setPolicies(rAssessment.policy_files)
      setTechScans(rAssessment.technical_scans_files)
      setEvaluations(rAssessment.assessment_evaluations)
      console.log(
        'here is the technical_scans_files: ',
        rAssessment.technical_scans_files
      )
    } catch (error: any) {
      addNotification({
        id: Date.now(),
        type: 'error',
        message: `${error}`,
        timestamp: Date.now()
      })
      void message.error(
        error.message ?? 'Error getting the assessment details'
      )
    } finally {
      setLoadingStates((prev) => ({ ...prev, loading: false }))
    }
  }, [])
  useEffect(() => {
    void fetchAssessmentData()
  }, [fetchAssessmentData])
  const getStatusBadgeStatus = (status: string) => {
    switch (status) {
      case 'Under Evaluation':
        return 'processing'
      case 'Technical Scan In Progress':
        return 'warning'
      case 'Active':
        return 'success'
      case 'Not Started':
        return 'error'
      default:
        return 'default'
    }
  }

  // Handle file upload changes
  async function handlePauseAssessment () {
    try {
      if (!statusData?.assessment_id) {
        void message.error('Assessment ID is required')
        return
      }
      setLoadingStates((prev) => ({ ...prev, pause_assessment_load: true }))
      const msg = await pauseAssessment({ assessment_id: statusData.assessment_id })
      await fetchAssessmentData()
      void message.success(msg ?? 'Assessment paused')
    } catch (error: any) {
      try {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
      } catch (error) {
        console.log(error)
      }
      void message.error(error?.message ?? 'Failed to pause assessment')
    } finally {
      setLoadingStates((prev) => ({ ...prev, pause_assessment_load: false }))
    }
  }

  async function handleRestartAssessment () {
    try {
      if (!statusData?.assessment_id) {
        void message.error('Assessment ID is required')
        return
      }
      setLoadingStates((prev) => ({ ...prev, pause_assessment_load: true }))
      const msg = await restartAssessment({ assessment_id: statusData.assessment_id })
      await fetchAssessmentData()
      void message.success(msg ?? 'Assessment restarted')
    } catch (error: any) {
      try {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
      } catch (error) {
        console.log(error)
      }
      void message.error(error?.message ?? 'Failed to restart assessment')
    } finally {
      setLoadingStates((prev) => ({ ...prev, pause_assessment_load: false }))
    }
  }

  async function handleStartAssessment () {
    try {
      if (!statusData?.assessment_id) {
        void message.error('Assessment ID is required')
        return
      }
      setLoadingStates((prev) => ({ ...prev, start_assessment_load: true }))
      const msg = await startAssessment({ assessment_id: statusData.assessment_id })
      await fetchAssessmentData()
      void message.success(msg ?? 'Assessment started')
    } catch (error: any) {
      try {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
      } catch (error) {
        console.log(error)
      }
      void message.error(error?.message ?? 'Failed to start assessment')
    } finally {
      setLoadingStates((prev) => ({ ...prev, start_assessment_load: false }))
    }
  }
  const handleDeleteAssessment = async () => {
    try {
      if (!statusData?.assessment_id) {
        void message.error('Assessment ID is required')
        return
      }
      setLoadingStates((prev) => ({ ...prev, delete_assessment_load: true }))
      const msg = await deleteAssessment({
        assessment_id: statusData?.assessment_id
      })
      navigate(ROUTES.OVERVIEW)
      void message.success(msg ?? 'Assessment deleted')
    } catch (error: any) {
      try {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
      } catch (error) {
        console.log(error)
      }
      void message.error(error?.message ?? 'Failed to delete assessment')
    } finally {
      setLoadingStates((prev) => ({ ...prev, delete_assessment_load: false }))
    }
  }

  async function handleUploadRoleArn (arn: string) {
    try {
      if (!statusData?.assessment_id) {
        void message.error('Assessment ID is required')
        return
      }
      if (!arn) {
        void message.error('AWS Role ARN is required')
        return
      }
      setLoadingStates((prev) => ({ ...prev, start_tech_scan_load: true }))
      const msg = await uploadTechScanRoleArn({
        assessment_id: statusData?.assessment_id,
        role_arn: arn
      })
      await fetchAssessmentData()
      void message.success(
        msg ?? 'Role ARN updated.'
      )
    } catch (error: any) {
      try {
        addNotification({
          id: Date.now(),
          type: 'error',
          message: `${error}`,
          timestamp: Date.now()
        })
      } catch (error) {
        console.log(error)
      }
      void message.error(error?.message ?? 'Failed to update Role Arn')
    } finally {
      setLoadingStates((prev) => ({ ...prev, start_tech_scan_load: false }))
    }
  }

  const buttonItems: any[] = [
    {
      label: 'Start Assessment',
      key: 'start-assessment',
      disabled: statusData?.status !== 'Not Started',
      loading: loadingStates.start_assessment_load,
      onClick: handleStartAssessment
    },
    {
      label: 'Delete Assessment',
      key: 'delete-assessment',
      disabled: statusData?.status !== 'Not Started',
      loading: loadingStates.delete_assessment_load,
      onClick: handleDeleteAssessment
    },
    {
      label: 'Pause Assessment',
      key: 'pause-assessment',
      disabled: statusData?.status !== 'Active',
      loading: loadingStates.pause_assessment_load,
      onClick: handlePauseAssessment
    },
    {
      label: 'Resume Assessment',
      key: 'resume-assessment',
      disabled: statusData?.status !== 'Paused',
      loading: loadingStates.pause_assessment_load,
      onClick: handleRestartAssessment
    },
    {
      label: 'Back',
      key: 'back-to-dashboard',
      onClick: () => {
        navigate(ROUTES.OVERVIEW)
      }
    }
  ]

  return {
    getStatusBadgeStatus,
    statusData,
    policies,
    techScans,
    evaluations,
    loadingStates,
    buttonItems,
    handleUploadRoleArn
  }
}

export { useAssessmentDetails }
