import ReactDOM from 'react-dom/client'
import { AppProvider, UserProvider } from 'context'
import './styles/index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { loadAmpConfig } from 'config'
import { Amplify } from 'aws-amplify'

async function configureAndStartApp () {
  const ampConfig = await loadAmpConfig()
  Amplify.configure(ampConfig)

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  )
  root.render(
    <AppProvider>
      <UserProvider>
        <App />
      </UserProvider>
    </AppProvider>
  )
}

configureAndStartApp().catch(console.error)

reportWebVitals()
