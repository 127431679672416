export const cmmcScope = [
  {
    value: 'ac.1',
    text: 'Access Control (AC)'
  },
  {
    value: 'ia.1',
    text: 'Identification and Authentication (IA)'
  },
  {
    value: 'ir.1',
    text: 'Incident Response (IR)'
  },
  {
    value: 'rm.1',
    text: 'Risk Management (RM)'
  },
  {
    value: 'ca.1',
    text: 'Security Assessment (CA)'
  },
  {
    value: 'au.1',
    text: 'Audit and Accountability (AU)'
  },
  {
    value: 'at.1',
    text: 'Awareness and Training (AT)'
  },
  {
    value: 'cm.1',
    text: 'Configuration Management (CM)'
  },
  {
    value: 'sc.1',
    text: 'System and Communications Protection (SC)'
  },
  {
    value: 'si.1',
    text: 'System and Information Integrity (SI)'
  }
]
