import React, { useState } from 'react'
import {
  Form,
  Radio,
  Button,
  Row,
  Col,
  Input,
  Space,
  message,
  Modal
} from 'antd'
import { scope } from 'data/tsc'
import PageTitle from 'components/core/Utils/PageTitle'
import type { ScopeItem, DataStructure } from 'types'
import { createSoc2Assessment } from 'services'
import { useNotifications } from 'hooks'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'config'

const SocScopingForm: React.FC = () => {
  const navigate = useNavigate()
  const { addNotification } = useNotifications()
  const scopeItems: ScopeItem[] = scope
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)
  const onFinish = (values: DataStructure) => {
    // delete values.assessment_scoping
    console.log('Received values:', values)
    if (values.pot === undefined) {
      void message.error(
        'Please select if this is going to be either technical scan, policy review or both'
      )
      return
    }
    let atLeastOneYesSelected = false

    for (const element of Object.values(values.assessment_scoping)) {
      if (element.in_scope === 'Yes') {
        atLeastOneYesSelected = true
        break
      }
    }

    if (!atLeastOneYesSelected) {
      void message.error('Please take a moment to scope the assessment. At least, one TSC must be selected')
      return
    }

    if (values.cadence === undefined) {
      void message.error(
        'Please select how often the assessment should run'
      )
      return
    }
    Modal.confirm({
      title: 'This is going to create the assessment',
      content: 'Do you want to proceed?',
      onOk: async () => {
        try {
          setLoading(true)
          const response = await createSoc2Assessment(values)
          if (response?.assessment_id) {
            navigate(`${ROUTES.ASSESSMENTS}/${response?.assessment_id}`)
          }

          void message.success(response?.message)
        } catch (error: any) {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
          void message.error(
            error.message ??
              'There was an error creating the assessment. Please try again later.'
          )
        } finally {
          setLoading(false)
        }
      },
      okText: 'Yes',
      cancelText: 'No, Go Back',
      okButtonProps: {
        disabled: loading
      },
      cancelButtonProps: {
        disabled: loading
      }
    })
  }

  return (
    <div className="scoping-form">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <PageTitle text="Scope the TSCs to be Assessed" />
        <Form.Item
        required
          style={{
            maxWidth: '700px'
          }}
          name="title"
          label="Title for the Assessment"
          rules={[
            {
              required: true,
              message: 'Please input the title for the assessment!'
            }
          ]}
        >
          <Input placeholder="Enter assessment title" />
        </Form.Item>

        <Form.Item required name="pot" label="Will the Assessment Cover either Policy, Technical Scan or both?">
          <Radio.Group>
            <Radio value="technical">Technical Scan Only(Apologies for the inconvenience. Policy scans have been disabled for now)</Radio>
            {/* <Radio value="policy">Policy Review Only</Radio>
            <Radio value="both">Both</Radio> */}
          </Radio.Group>
        </Form.Item>

        <Form.Item required label="Please take a moment to scope the assessment. Any control family not checked will be considered out of scope.">
          <Space direction="vertical" size="middle">
            {scopeItems.map((item) => (
              <Row
                key={item.value}
                gutter={16}
                align="middle"
                className="form-row"
              >
                <Col xs={24} sm={12} className="item-label">
                  {item.text}
                </Col>
                <Col xs={24} sm={12}>
                  <Form.Item
                    name={['assessment_scoping', item.value, 'in_scope']}
                    noStyle
                  >
                    <Radio.Group className="radio-group">
                      <Radio value="Yes">Yes</Radio>
                      <Radio value="No">No</Radio>
                    </Radio.Group>
                  </Form.Item>
                </Col>
              </Row>
            ))}
          </Space>
        </Form.Item>
        <Form.Item required name="cadence" label="How often should the assessment run?">
          <Radio.Group>
            <Radio value="daily">Daily</Radio>
            <Radio value="weekly">Weekly</Radio>
            <Radio value="bi_weekly">Bi-Weekly</Radio>
            <Radio value="monthly">Monthly</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item>
          <Button
            shape="round"
            size="middle"
            loading={loading}
            htmlType="submit"
            className="submit-button"
          >
            Create Assessment
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default SocScopingForm
