import { fetchUserAttributes } from 'aws-amplify/auth'
import { domain, resources } from 'config'
import { createCoreAxiosInstance } from 'hooks'
import type {
  ASSESSMENT_DETAILS,
  Plan,
  SUBSCRIPTION,
  FrameworkData,
  USER_DT,
  AmpConfig
} from 'types'

const coreAxiosInstance = createCoreAxiosInstance()

async function getAmpConfig (): Promise<AmpConfig> {
  try {
    const path = `${domain.app}${resources.ampConfig}`
    const response = await coreAxiosInstance.get<AmpConfig>(path)
    return response.data
  } catch (error) {
    console.error('Error fetching subscription:', error)
    return {
      userPoolId: '',
      userPoolWebClientId: '',
      domain: '',
      redirectSignIn: '',
      redirectSignOut: ''
    }
  }
}
async function getUser (): Promise<USER_DT | null> {
  try {
    const cognitoUser = await fetchUserAttributes()
    const userAttributes = cognitoUser
    console.log('userAttributes', userAttributes)
    const convertUserTypes = (attributes: Record<string, any>): USER_DT => {
      return {
        usr_id: attributes.sub ?? '',
        avatarUrl: attributes.profilePicture ?? '',
        given_name: attributes.given_name ?? '',
        family_name: attributes.family_name ?? '',
        email: attributes.email ?? '',
        email_verified: attributes.email_verified ?? false,
        notifications: attributes.notifications ?? []
      }
    }

    // Ensure that userAttributes is not undefined before trying to convert
    if (userAttributes) {
      const convertedData = convertUserTypes(userAttributes)
      return convertedData
    } else {
      return null // Return null if userAttributes is undefined
    }
  } catch (error) {
    console.error(error)
    return null
  }
}

async function createSubscribeSession (data: { plan: Plan, cadence: 'monthly' | 'yearly' }): Promise<{ message: string, location: Location | null | undefined }> {
  try {
    const path = `${domain.stripe}${resources.stripeSession}`
    const response = await coreAxiosInstance.post<any>(path, data)
    const responseData = response.data
    const message: string = responseData.message
    const location: Location = responseData.location
    return { message, location }
  } catch (error: any) {
    console.log(error.message)
    return { message: error.message, location: undefined }
  }
}

async function getMySubscription (): Promise<SUBSCRIPTION> {
  try {
    const path = `${domain.subscriptions}${resources.getSubscription}`
    const response = await coreAxiosInstance.get<SUBSCRIPTION>(path)
    return response.data
  } catch (error) {
    console.error('Error fetching subscription:', error)
    return {
      tier: null,
      status: null,
      created: null
    }
  }
}

async function getAllAssessments (param: { limit?: number, exclusive_start_key?: string } = {}) {
  // Set default values if not provided
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const { limit = 10, exclusive_start_key } = param

  // Construct the parameters with default values
  const parameters = {
    limit,
    exclusive_start_key
  }

  // If exclusive_start_key is not provided or is an empty string, don't include it in params
  if (!parameters.exclusive_start_key) {
    delete parameters.exclusive_start_key
  }

  const path = `${domain.assessment}${resources.all}`
  const response = await coreAxiosInstance.get(path, { params: parameters })
  console.log(response)
  return response.data
}

async function startAssessment (assessmentId: { assessment_id: string }): Promise<string> {
  const path = `${domain.assessment}${resources.startAssessment}`
  const response = await coreAxiosInstance.post(path, assessmentId)
  return response.data.message
}

async function deleteAssessment (assessmentId: { assessment_id: string }): Promise<string> {
  const path = `${domain.assessment}${resources.deleteAssessment}`
  const response = await coreAxiosInstance.post(path, assessmentId)
  return response.data.message
}

async function getAssessment (param: { assessment_id: string }): Promise<ASSESSMENT_DETAILS> {
  const path = `${domain.assessment}${resources.assessment}`
  const response = await coreAxiosInstance.get<ASSESSMENT_DETAILS>(path, { params: param })
  return response.data
}

async function uploadTechScanRoleArn (data: { assessment_id: string, role_arn: string }): Promise<string> {
  const path = `${domain.assessment}${resources.techScanRoleArn}`
  const response = await coreAxiosInstance.post(path, data)
  return response.data.message
}

async function getDashboardData (param: { assessment_id: string }): Promise<FrameworkData> {
  const path = `${resources.reportsPath}${resources.json}`
  const response = await coreAxiosInstance.get(path, { params: param })
  return response.data.tech_report
}

async function askQBiBot (data: { assessment_id: string, inquiry: string }): Promise<string> {
  try {
    const path = `${domain.bibot}${resources.q}`
    const response = await coreAxiosInstance.post<any>(path, data)
    return response.data.message
  } catch (error: any) {
    console.log(error.message)
    return error.message
  }
}

async function initOnDemandScanData (param: { assessment_id: string }): Promise<string> {
  const path = `${resources.reportsPath}${resources.onDemand}`
  const response = await coreAxiosInstance.get(path, { params: param })
  return response.data.message
}

async function askTimedOutLocalBiBot (data: { inquiry: string, sessionId: string, chatId: string, tries: number }): Promise<string> {
  try {
    const path = `${domain.bibot}${resources.timedOutLocalQ}`
    const response = await coreAxiosInstance.post<any>(path, data)
    console.log(response)
    return response.data.message
  } catch (error: any) {
    console.log(error.message)
    return error.message
  }
}

async function askLocalBiBot (data: { inquiry: string, endSession?: boolean, sessionId: string, chatId: string }): Promise<string> {
  try {
    const path = `${domain.bibot}${resources.localQ}`
    const response = await coreAxiosInstance.post<any>(path, data)
    console.log(response)
    return response.data.message
  } catch (error1: any) {
    console.log(error1.message)
    if (error1.code === 'ECONNABORTED' || error1.message.includes('Endpoint request timed out')) {
      try {
        return await askTimedOutLocalBiBot({
          inquiry: data.inquiry,
          sessionId: data.sessionId,
          chatId: data.chatId,
          tries: 2
        })
      } catch (error2: any) {
        if (error2.code === 'ECONNABORTED' || error2.message.includes('Endpoint request timed out')) {
          try {
            return await askTimedOutLocalBiBot({
              inquiry: data.inquiry,
              sessionId: data.sessionId,
              chatId: data.chatId,
              tries: 3
            })
          } catch (error3: any) {
            return error3.message
          }
        } else {
          return error2.message
        }
      }
    } else {
      return error1.message
    }
  }
}

async function pauseAssessment (assessmentId: { assessment_id: string }): Promise<string> {
  const path = `${domain.assessment}${resources.pauseAssessment}`
  const response = await coreAxiosInstance.post(path, assessmentId)
  return response.data.message
}

async function restartAssessment (assessmentId: { assessment_id: string }): Promise<string> {
  const path = `${domain.assessment}${resources.restartAssessment}`
  const response = await coreAxiosInstance.post(path, assessmentId)
  return response.data.message
}

export {
  getAmpConfig,
  getUser,
  createSubscribeSession,
  getMySubscription,
  getAllAssessments,
  startAssessment,
  getAssessment,
  deleteAssessment,
  uploadTechScanRoleArn,
  getDashboardData,
  askQBiBot,
  initOnDemandScanData,
  askLocalBiBot,
  pauseAssessment,
  restartAssessment
}
