import React from 'react'
import { Modal, Button, Steps, Input, Form, Typography, Tooltip } from 'antd'
import { SecurityRounded } from '@mui/icons-material'

const { Step } = Steps

interface StepByStepDialogProps {
  // icon?: Icon
  openInstructionsText: string
  disableInstructionText: string
  disableInstructionButton: boolean
  isVisible: boolean
  clientRoleArn: string
  setIsVisible: (isVisible: boolean) => void
  setClientRoleArn: (clientRoleArn: string) => void
  handleArnHookFunction: (arn: string) => Promise<void>
  loading: boolean
}

const StepByStepDialog: React.FC<StepByStepDialogProps> = ({ openInstructionsText, disableInstructionText, disableInstructionButton, isVisible, setIsVisible, clientRoleArn, setClientRoleArn, handleArnHookFunction, loading }) => {
  const showModal = () => {
    setIsVisible(true)
  }

  const handleOk = async () => {
    setIsVisible(false)
    await handleArnHookFunction(clientRoleArn)
    console.log(clientRoleArn)
  }

  const handleCancel = () => {
    setIsVisible(false)
  }

  const onClientRoleArnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setClientRoleArn(e.target.value)
  }

  const button = (
    <Button
      disabled={disableInstructionButton}
      onClick={showModal}
      loading={loading}
      icon={<SecurityRounded style={{ height: '15px', width: '15px' }}/>}
    >
      {openInstructionsText}
    </Button>
  )

  return (
    <>
      {disableInstructionButton
        ? (
    <Tooltip title={disableInstructionText}>
      <span className="ant-tooltip-wrapper">{button}</span>
    </Tooltip>
          )
        : (
            button
          )}
      <Modal
        title="AWS Assume Role Instructions"
        open={isVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
      >
        {/* */}
        <Steps direction="vertical" current={-1}>
          <Step title="Login to AWS" description="Log in to your AWS Management Console." />
          <Step title="Navigate to IAM" description="Go to the IAM (Identity and Access Management) service." />
          <Step title="Create New Role" description="Select 'Roles' from the sidebar and click 'Create role'." />
          <Step title="Choose Role Type" description="Choose 'Custom trust policy' and update the Trust Policy Principal with the following" />
          <Step
            title="Update Principal and Click Next"
            description={
              <span>
                {'{ "AWS": '}
                <Typography.Text code>
                arn:aws:iam::512744327595:role/preauditai
                </Typography.Text>
                {' }'}
              </span>
            }
          />

          <Step
            title="Set Permissions and Click Next"
            description={
              <span>
                {'Attach the necessary policies to your role. At minimum, the following are required: '}
                <Typography.Text code>
                  arn:aws:iam::aws:policy/SecurityAudit
                </Typography.Text>
                {'and'}
                <Typography.Text code>
                  arn:aws:iam::aws:policy/job-function/ViewOnlyAccess
                </Typography.Text>
              </span>
            }
          />
          <Step title="Review and Create" description="Review your settings, name it (e.g. preauditai), optionally add description and click 'Create role'." />
          <Step title="View and Provide Created Role ARN" description="Click View role or navigate to the newly created role, copy the role ARN and paste it below" />
        </Steps>
        {/* <Typography.Paragraph>
          <strong>Source Role ARN </strong> <Typography.Text code>&quot;AWS&quot;: &quot;arn:aws:iam::952311676553:role/preauditai&quot; </Typography.Text>
        </Typography.Paragraph> */}
        <Form>
          <Form.Item label="Enter the ARN of the role you've created">
            <Input placeholder="arn:aws:iam::YOUR-ACCOUNT-ID:role/YOUR-ROLE-NAME" onChange={onClientRoleArnChange} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  )
}

export default StepByStepDialog
