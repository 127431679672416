import { Badge, Paper, Title, Text, Button, Group, Image, Radio, Space, Skeleton, Alert } from '@mantine/core'
import logo from '../../assets/logo/preauditai-high-resolution-logo-black-transparent.png'
import { createSubscribeSession, getMySubscription } from 'services'
import type { Plan, SUBSCRIPTION } from 'types'
import { useCallback, useEffect, useState } from 'react'
import { message } from 'antd'
import { plans } from 'data'
const formatDate = (timestamp: any) => {
  if (!timestamp) return 'N/A'
  return new Date(timestamp * 1000).toLocaleDateString('en-US', {
    year: 'numeric', month: 'long', day: 'numeric'
  })
}

function SkeletonComponent () {
  return (
    <Alert variant="light" color="rgba(51, 51, 51, 1)" style={{ color: '#000', marginBottom: '24px' }}>
      <Skeleton height={10} mt={12} width="30%" radius="xl" />
      <Skeleton height={10} mt={12} width="40%" radius="xl" />
      <Skeleton height={10} mt={12} width="50%" radius="xl" />
      <Skeleton height={10} mt={12} width="60%" radius="xl" />
      <Skeleton height={10} mt={12} width="70%" radius="xl" />
    </Alert>
  )
}
const SubscriptionPage = () => {
  const [subscription, setSubscription] = useState<SUBSCRIPTION>()
  const [loadingPlan, setLoadingPlan] = useState<string | null>(null)
  const [selectedCadence, setSelectedCadence] = useState<
  Record<string, 'monthly' | 'yearly'>
  >({})

  const handleSubscribe = async ({
    plan,
    cadence
  }: {
    plan: Plan
    cadence: 'monthly' | 'yearly'
  }) => {
    try {
      setLoadingPlan(plan.name)
      const response = await createSubscribeSession({ plan, cadence })
      if (!response.location) {
        void message.info(response.message)
        await handleGetSubscription()
      } else {
        window.location = response.location
      }
    } catch (error) {
      console.error('Failed to create subscription session:', error)
      void message.error(
        'Failed to create subscription session. Please try again.'
      )
    } finally {
      setLoadingPlan(null)
    }
  }

  const handleGetSubscription = useCallback(async () => {
    const response = await getMySubscription()
    if (response) {
      setSubscription(response)
    }
  }, [])
  useEffect(() => {
    void handleGetSubscription()
  }, [handleGetSubscription])

  useEffect(() => {
    const query = new URLSearchParams(window.location.search)

    if (query.get('success')) {
      void message.success(
        'Subscription successful! You will receive an email confirmation.'
      )
      setTimeout(() => {
        void handleGetSubscription()
      }, 2000)
    }

    if (query.get('canceled')) {
      void message.info("Payment canceled -- subscribe when you're ready.")
    }
  }, [])

  const SubscriptionStatus: React.FC = () => (
    <div>
   { subscription
     ? (
      <Alert variant="light" color="rgba(51, 51, 51, 1)" style={{ color: '#000', marginBottom: '24px' }}>
        <Title order={4}>Current Subscription</Title>
        <Text><strong style={{ color: '#000' }}>Status: </strong> <Badge color={subscription.status === 'active' ? 'green' : 'red'}>{subscription.status?.toUpperCase()}</Badge></Text>
        <Text style={{ color: '#000' }}><strong>Tier: </strong>{subscription.tier ? subscription.tier.toUpperCase() : 'N/A'}</Text>
        <Text style={{ color: '#000' }}><strong>Subscription Date: </strong>{formatDate(subscription.created)}</Text>
        {subscription.next_billing_date && <Text style={{ color: '#000' }}><strong>Next Billing Date: </strong> {formatDate(subscription.next_billing_date)}</Text>}
      </Alert>
       )
     : <SkeletonComponent/>}
    </div>

  )
  const ProductDisplay = () => (
    <div style={{ maxWidth: 1400, margin: 'auto' }}>
      <Group style={{ marginBottom: '24px' }} justify="center">
        <Image src={logo} alt="PreAuditAi Logo" style={{ width: '14%' }}/>
      </Group>
      <SubscriptionStatus />
      <Title order={4} style={{ marginBottom: '10px' }}>Choose Your Plan</Title>
      <Group justify='flex-start'>
        {plans.map((plan) => (
          <Paper
            withBorder
            shadow="md"
            p="md"
            radius="md"
            key={plan.name}
            style={{
              width: 300,
              minHeight: 500,
              display: 'flex',
              flexGrow: 1,
              flexDirection: 'column',
              justifyContent: 'space-between',
              backgroundColor: '#323232',
              color: '#fff'
            }}
          >
            <div>
              <Title order={3}>{plan.name}</Title>
              <Text size="lg" style={{ marginBottom: 10 }}>
                {plan.price}
              </Text>
              <Text
                size="sm"
                style={{ minHeight: 60 }}
              >{`${plan.accounts} accounts, ${plan.complianceFrameworks} compliance frameworks`}</Text>
              <ul>
                {plan.features.map((feature, index) => (
                  <li key={index}>{feature}</li>
                ))}
              </ul>
              {plan.name !== 'Always Free' && <Radio.Group
                value={selectedCadence[plan.name] || 'monthly'}
                onChange={(value) => {
                  setSelectedCadence({
                    ...selectedCadence,
                    [plan.name]: value as 'monthly' | 'yearly'
                  })
                }}
              >
                <Radio value="monthly" label="Monthly" />
                <Space h="md" />
                <Radio value="yearly" label="Yearly" />
              </Radio.Group>}
            </div>
            <Button
              loading={loadingPlan === plan.name}
              fullWidth
              variant="light"
              color="blue"
              onClick={async () => {
                await handleSubscribe({
                  plan,
                  cadence: selectedCadence[plan.name] || 'monthly'
                })
              }}
              disabled={!!loadingPlan}
            >
              Choose {plan.name}
            </Button>
          </Paper>
        ))}
      </Group>
      <Text style={{ marginTop: '24px', color: '#000', fontStyle: 'italic' }}>
        * Billed Annually
      </Text>
    </div>
  )

  return (
    <>
      <ProductDisplay />
    </>
  )
}

export { SubscriptionPage }
