import { createPciDssAxiosInstance } from 'hooks'
import { domain, resources } from 'config/endpointEnums'

const pciDssAxiosInstance = createPciDssAxiosInstance()

interface CreatedAssessmentResponse {
  message: string
  assessment_id: string
}
async function createPciDssAssessment (title: string): Promise<CreatedAssessmentResponse> {
  const path = `${domain.pciDss}${resources.createAssessment}`
  const response = await pciDssAxiosInstance.post<CreatedAssessmentResponse>(path, title)
  console.log(response)
  return response.data
}

export {
  createPciDssAssessment
}
