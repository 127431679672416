import React, { useContext } from 'react'
import { Switch } from 'antd'
import { AppContext } from 'context'
import { appStateEnums } from 'config'
import PageTitle from './PageTitle'

const SettingsPage: React.FC = () => {
  const { state, dispatch } = useContext(AppContext)
  const toggleHideMe = function (checked: boolean) {
    dispatch({ type: appStateEnums.HIDE_IMPORTANT_NOTIFICATIONS, hideImportantMessages: checked })
  }

  const toggleTheme = function (theme: string) {
    console.log('THis is the theme ', theme)
    dispatch({ type: appStateEnums.THEME, hideImportantMessages: theme })
  }
  return (
        <div>
                <PageTitle text="App Settings" />
            <h3>Theme</h3>
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <h5 style={{ marginRight: '10px' }}>Dark</h5>
                <Switch onChange={checked => { toggleTheme(checked ? 'light' : 'dark') }} />
                <h5 style={{ marginLeft: '10px' }}>Light</h5>
            </span>
            <h3>Notifications</h3>
            <span style={{ display: 'flex', alignItems: 'center' }}>
                <h5 style={{ marginRight: '10px' }}>Hide Important Notification Banner</h5>
                <Switch checked={state.hideImportantMessages} onChange={checked => { toggleHideMe(checked) }} />
            </span>
        </div>
  )
}

export default SettingsPage
