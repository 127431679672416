import axios, { type AxiosInstance } from 'axios'
import { getIdToken } from 'utils'
import { endpoints } from 'config/endpointEnums'

const hipaaBaseUrl = endpoints.hipaa

export const createHipaaAxiosInstance = (params = {}, headers = {}): AxiosInstance => {
  const instance = axios.create({
    baseURL: hipaaBaseUrl,
    timeout: 365000,
    headers: {
      'Content-Type': 'application/json',
      ...headers
    },
    ...params
  })

  instance.interceptors.request.use(async (config) => {
    try {
      const idToken = await getIdToken()
      config.headers.Authorization = idToken
    } catch (error) {
      console.error('Error attaching token', error)
    }
    return config
  })

  instance.interceptors.response.use(
    (response) => {
      return response
    },
    async (error) => {
      let errMsg = 'An unknown error occurred'
      if (error.response) {
        errMsg = error.response.data.message
      } else if (error.request) {
        errMsg = 'No response received from the server.'
      } else {
        errMsg = `Error setting up the request: ${error.message}`
      }
      return await Promise.reject(new Error(errMsg))
    }
  )

  return instance
}
