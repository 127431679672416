import React, { useState } from 'react'
import { Form, Button, Row, Col, Input, Space, Checkbox, message, Modal } from 'antd'
import { awsScope } from 'data'
import { useNavigate } from 'react-router-dom'
import type { AWSDataStructure } from 'types'
import { ROUTES } from 'config'
import { useNotifications } from 'hooks'
import { createAwsAssessment } from 'services'

const AWSScopingForm = () => {
  const { addNotification } = useNotifications()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const onFinish = async (values: AWSDataStructure) => {
    console.log('Received values:', values)
    let atLeastOneYesSelected = false

    for (const element of Object.values(values.assessment_scoping)) {
      if (element.in_scope) {
        console.log(element)
        atLeastOneYesSelected = true
        break
      }
    }

    if (!atLeastOneYesSelected) {
      void message.error('Please take a moment to scope the assessment. At least, one security pillar must be selected')
      return
    }
    Modal.confirm({
      title: 'This is going to create the assessment',
      content: 'Do you want to proceed?',
      onOk: async () => {
        try {
          setLoading(true)
          const response = await createAwsAssessment(values)
          if (response?.assessment_id) {
            navigate(`${ROUTES.ASSESSMENTS}/${response?.assessment_id}`)
          }

          void message.success(response?.message)
        } catch (error: any) {
          addNotification({
            id: Date.now(),
            type: 'error',
            message: `${error}`,
            timestamp: Date.now()
          })
          void message.error(
            error.message)
        } finally {
          setLoading(false)
        }
      },
      okText: 'Yes',
      cancelText: 'No, Go Back',
      okButtonProps: {
        disabled: loading
      },
      cancelButtonProps: {
        disabled: loading
      }
    })
  }

  return (
    <div className="scoping-form">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <h2>Select the AWS Security Pillars. We recommend you select all</h2>
        <Form.Item
          name="title"
          label="Title for the Assessment"
          rules={[{ required: true, message: 'Please input the title for the assessment!' }]}
        >
          <Input placeholder="Enter assessment title" />
        </Form.Item>

        <Form.Item label="Select AWS Security Pillar Areas in Scope">
          <Space direction="vertical" size="large">
            {awsScope.map((item) => (
              <Row key={item.value} gutter={16} align="middle">
                <Col span={24}>
                  <Form.Item
                    name={['assessment_scoping', item.value, 'in_scope']}
                    valuePropName="checked"
                    initialValue={true}
                  >
                    <Checkbox>{item.text}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            ))}
          </Space>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create Assessment
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default AWSScopingForm
