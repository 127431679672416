export enum soc2 {
  loadPriorAnswers = 'LOAD_ASSESSMENT_ANSWERS',
  setAssessmentQuestions = 'SET_ASSESSMENT_QUESTIONS',
  setAssessmentReviewData = 'SET_ASSESSMENT_REVIEW_DATA',
  setReportData = 'SET_REPORT_DATA',
  setOverviewData = 'SET_OVERVIEW_DATA',
  updateSocAssessments = 'UPDATE_SOC_ASSESSMENTS',
  setAssessment = 'SET_ASSESSMENT',
  updateNamesAndEmails = 'UPDATE_NAMES_AND_EMAILS',
}
