import React, { useState } from 'react'
import { Menu, Dropdown } from 'antd'
import { MoreHoriz } from '@mui/icons-material'

import {
  RadarChartOutlined,
  WarningOutlined,
  FundProjectionScreenOutlined
} from '@ant-design/icons'
import type {
  CriticalFindingsDT,
  NewVsOldFindingsDT,
  PersistentFindingsDT,
  PredictedFutureFindingsDT,
  RadarChartDataDT,
  RawPredictedDataDT
} from 'types'

import {
  RadarCharts,
  CriticalFindings,
  PredictedFuture,
  EmergingFindings,
  PersistentFindings
} from './widgets'

interface MidSectionChartOptionsProps {
  criticalFindings: CriticalFindingsDT[]
  newFindings: NewVsOldFindingsDT[]
  persistentFindings: PersistentFindingsDT[]
  predictedFutureFindings: PredictedFutureFindingsDT[]
  radarChartData: RadarChartDataDT[]
  rawPredictionData: RawPredictedDataDT
}

const MidSectionChartOptions: React.FC<MidSectionChartOptionsProps> = ({
  criticalFindings,
  newFindings,
  persistentFindings,
  predictedFutureFindings,
  radarChartData,
  rawPredictionData
}) => {
  const [chartType, setChartType] = useState<
  'radar' | 'critical' | 'predictions' | 'new_v_exist' | 'repeated'
  >('radar')

  const menu = (
    <Menu>
      <Menu.Item
        key="radar"
        icon={<RadarChartOutlined />}
        onClick={() => {
          setChartType('radar')
        }}
      >
        Best Practices Progress Radar
      </Menu.Item>
      <Menu.Item
        key="critical"
        icon={<WarningOutlined />}
        onClick={() => {
          setChartType('critical')
        }}
      >
        Services with Critical Findings
      </Menu.Item>
      <Menu.Item
        key="predictions"
        icon={<FundProjectionScreenOutlined />}
        onClick={() => {
          setChartType('predictions')
        }}
      >
        Predictions
      </Menu.Item>
      {/* <Menu.Item
        key="new_v_exist"
        icon={<DiffOutlined />}
        onClick={() => {
          setChartType('new_v_exist')
        }}
      >
        New vs Existing
      </Menu.Item>
      <Menu.Item
        key="repeated"
        icon={<SyncOutlined />}
        onClick={() => {
          setChartType('repeated')
        }}
      >
        Repeated Findings
      </Menu.Item> */}
    </Menu>
  )

  return (
    <div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          marginRight: '2px',
          marginTop: '2px'
        }}
      >
        <Dropdown overlay={menu} trigger={['click']}>
          <a
            className="ant-dropdown-link"
            onClick={(e) => {
              e.preventDefault()
            }}
          >
            <MoreHoriz />
          </a>
        </Dropdown>
      </div>
      {chartType === 'radar' && <RadarCharts data={radarChartData}/>}
      {chartType === 'critical' && <CriticalFindings data={criticalFindings} />}
      {chartType === 'predictions' && <PredictedFuture data={predictedFutureFindings} rawPredictionData={rawPredictionData} />}
      {chartType === 'new_v_exist' && <EmergingFindings data={newFindings} />}
      {chartType === 'repeated' && <PersistentFindings data={persistentFindings} />}
    </div>
  )
}

export { MidSectionChartOptions }
