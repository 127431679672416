/* eslint-disable react/prop-types */
import { Radar, RadarChart, PolarGrid, Legend, PolarAngleAxis, PolarRadiusAxis, ResponsiveContainer, Tooltip } from 'recharts'
import { type RadarChartDataDT } from 'types'
interface RadarChartsProps {
  data: RadarChartDataDT[]
}

const RadarCharts: React.FC<RadarChartsProps> = ({ data }) => {
  const formattedData = data.map(item => {
    return {
      ...item,
      subject: item.domain,
      A: Math.round(item.previous_pass_rate * 100),
      B: Math.round(item.current_pass_rate * 100),
      fullMark: 100
    }
  })
  return (<div style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'column', justifyContent: 'center' }}>
         <h2 style={{ textAlign: 'center', marginBottom: '0px', marginTop: '0px' }}>Progress Toward Best Practices</h2>
    <RadarChart style={{ width: '100%' }} cx={250} cy={250} outerRadius={200} width={500} height={500} data={formattedData}>
      <PolarGrid />
      <PolarAngleAxis dataKey="domain" />
      <PolarRadiusAxis angle={30} domain={[0, 100]} />
      <Radar name="Prior" dataKey="A" stroke="#8884d8" fill="#8884d8" fillOpacity={0.2} />
      <Radar name="Current" dataKey="B" stroke="#82ca9d" fill="#82ca9d" fillOpacity={0.2} />
      <Tooltip/>
      <Legend />
    </RadarChart>
    <h4 style={{ textAlign: 'right' }}>**Based on industry standards</h4>
    </div>
  )
}

export { RadarCharts }
