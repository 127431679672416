import { createHipaaAxiosInstance } from 'hooks'
import { domain, resources } from 'config/endpointEnums'

const hipaaAxiosInstance = createHipaaAxiosInstance()

interface CreatedAssessmentResponse {
  message: string
  assessment_id: string
}
async function createHipaaAssessment (title: string): Promise<CreatedAssessmentResponse> {
  const path = `${domain.hipaa}${resources.createAssessment}`
  const response = await hipaaAxiosInstance.post<CreatedAssessmentResponse>(path, title)
  console.log(response)
  return response.data
}

export {
  createHipaaAssessment
}
