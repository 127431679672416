export const nistScope = [
  {
    value: 'ac',
    text: 'Access Control'
  },
  {
    value: 'at',
    text: 'Awareness and Training'
  },
  {
    value: 'au',
    text: 'Audit and Accountability'
  },
  {
    value: 'ca',
    text: 'Security Assessment and Authorization'
  },
  {
    value: 'cm',
    text: 'Configuration Management'
  },
  {
    value: 'cp',
    text: 'Contingency Planning'
  },
  {
    value: 'ia',
    text: 'Identification and Authentication'
  },
  {
    value: 'ir',
    text: 'Incident Response'
  },
  {
    value: 'ma',
    text: 'Maintenance'
  },
  {
    value: 'mp',
    text: 'Media Protection'
  },
  {
    value: 'pe',
    text: 'Physical and Environmental Protection'
  },
  {
    value: 'pl',
    text: 'Planning'
  },
  {
    value: 'ps',
    text: 'Personnel Security'
  },
  {
    value: 'ra',
    text: 'Risk Assessment'
  },
  {
    value: 'sa',
    text: 'System and Services Acquisition'
  },
  {
    value: 'sc',
    text: 'System and Communications Protection'
  },
  {
    value: 'si',
    text: 'System and Information Integrity'
  },
  {
    value: 'sr',
    text: 'Supply Chain Risk Management'
  }
]
