import React, { useState, useEffect } from 'react'
export default function MobileView () {
  const [isMobile, setIsMobile] = useState<boolean>(false)
  useEffect(() => {
    const isMobileDevice = () => {
      const screenWidth = window.innerWidth
      const mobileThreshold = 768
      setIsMobile(screenWidth < mobileThreshold)
    }
    isMobileDevice()
    window.addEventListener('resize', isMobileDevice)
    return () => { // clean up event listner on when component unmount
      window.removeEventListener('resize', isMobileDevice)
    }
  }, [])
  return isMobile
}
