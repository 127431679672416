const frameworkDetector = (assessmentType: 'soc_2' | 'aws_caf_sec' | 'fedramp_mod_rev_4_aws' | 'hipaa_aws' | 'pci_dss_aws' | 'gdpr_aws') => {
  switch (assessmentType) {
    case 'soc_2':
      return 'SOC 2'
    case 'aws_caf_sec':
      return 'AWS Security Best Practices'
    case 'fedramp_mod_rev_4_aws':
      return 'FedRamp Moderate Rev 4'
    case 'hipaa_aws':
      return 'HIPAA'
    case 'pci_dss_aws':
      return 'PCI DSS'
    case 'gdpr_aws':
      return 'GDPR'
    default:
      return 'MISSING'
  }
}

export { frameworkDetector }
