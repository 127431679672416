import {
  Authenticator,
  CheckboxField,
  TextField,
  SelectField,
  useAuthenticator,
  PhoneNumberField,
  Heading,
  useTheme
} from '@aws-amplify/ui-react'
import '@aws-amplify/ui-react/styles.css'
import '../../../styles/RegisterPageStyle.css'
import { APP_DATA, ROUTES } from 'config'
import { UserContext } from 'context'
import React, { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from '../../../assets/logo/logo.png'
import { signUp } from 'aws-amplify/auth'
import { message } from 'antd'
interface RegisterProps {
  initiatePage: 'signIn' | 'signUp' | 'forgotPassword' | undefined
}

const RegisterPage: React.FC<RegisterProps> = ({ initiatePage }) => {
  // const [role, setRole] = useState<string>()
  // const [companyName, setCompanyName] = useState<string>()
  // const [acknowledgement, setAcknowledgement] = useState<boolean>()

  const navigate = useNavigate()
  const { state } = useContext(UserContext)

  useEffect(() => {
    if (state.isAuthenticated && state.user?.email_verified) {
      navigate(ROUTES.OVERVIEW)
    }
  }, [state])
  const emailPattern = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/
  const formFields = {
    // signUp: {
    //   'custom:companyName':
    //   {
    //     label: 'Company Name',
    //     placeholder: 'Enter your company name',
    //     isRequired: true,
    //     order: 1
    //   },
    //   'custom:role':
    //   {
    //     label: 'Role',
    //     placeholder: 'Enter your role',
    //     isRequired: true,
    //     order: 2
    //   }
    // },
    setupTotp: {
      QR: {
        totpIssuer: APP_DATA.APP_NAME
      }
    }
  }

  return (
    <div
      className='page-background'
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        textAlign: 'left'
      }}
    >
      <a href={ROUTES.HOME}>
      <img src={logo} alt='' className='preAuditLogo-on-registerPage' />
      </a>
      <Authenticator
        // Default to Sign Up screen
        formFields={formFields}
        initialState={initiatePage}
        components={{
          SignUp: {
            FormFields () {
              const { validationErrors } = useAuthenticator()
              return (
                <>
                  {/* Re-use default `Authenticator.SignUp.FormFields` */}
                  <Authenticator.SignUp.FormFields />

                   <div className='fieldContainer'>
                   <TextField
                    name="given_name"
                    label="First Name"
                    required={true}
                    errorMessage={validationErrors.given_name as string}
                    hasError={!!validationErrors.given_name}
                  />
                  <TextField
                    name="family_name"
                    label="Last Name"
                    required={true}
                    errorMessage={validationErrors.family_name as string}
                    hasError={!!validationErrors.family_name}
                  />
                   </div>

                   <div className='fieldContainer'>
                   <TextField
                    name="custom:companyName"
                    label="Company Name"
                    required={true}
                    // onChange={(e) => { setCompanyName(e.target.value) }}
                    errorMessage={validationErrors.companyName as string}
                    hasError={!!validationErrors.companyName}
                  />
                    <SelectField
                    style={{ maxWidth: '200px' }}
                    name="custom:role"
                    className='input-select-field'
                    label="Current Role"
                    // onChange={(e) => { setRole(e.target.value) }}
                    errorMessage={validationErrors.role as string}
                    hasError={!!validationErrors.role}
                  >
                    <option value="">Select your role...</option>
                    {roles.map((role, index) => ( // Added index as key when roles are not unique
                      <option key={index} value={role}>{role}</option>
                    ))}
                  </SelectField>

                   </div>
                   <PhoneNumberField
                    defaultDialCode='+1'
                    name='phone_number'
                    label='Phone Number'
                    dialCodeName='country_code'
                    dialCodeLabel='Dial Code'
                    isRequired={true}
                    placeholder="234-567-8910"
                    errorMessage={validationErrors.phone_number as string}
                    hasError={!!validationErrors.phone_number}
                    />
                  <CheckboxField
                    name="custom:acknowledgement"
                    value="true"
                    label={
                      <span>
                        I agree with the <a href={`${process.env.PUBLIC_URL}/privacy.html`} target="_blank" rel="noopener noreferrer">Terms & Conditions</a>
                      </span>
                    }
                    required={true}
                    errorMessage={validationErrors.acknowledgement as string}
                    hasError={!!validationErrors.acknowledgement}
                    // onChange={(e) => { setAcknowledgement(e.target.checked) }}
                  />

                </>
              )
            }
          },
          SetupTotp: {
            Header () {
              const { tokens } = useTheme()
              return (
                <Heading
                  padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
                  level={3}
                >
                  Setup You MFA Device
                </Heading>
              )
            }
          }
        }}
        services={{
          async handleSignUp (formData) {
            console.log('formdata', formData)
            const { username, password, options } = formData

            return await signUp({
              username,
              password,
              options:
              {
                autoSignIn: true,
                userAttributes: {
                  email: username,
                  given_name: options?.userAttributes?.given_name,
                  family_name: options?.userAttributes?.family_name,
                  phone_number: options?.userAttributes?.phone_number,
                  'custom:role': options?.userAttributes?.['custom:role'],
                  'custom:companyName': options?.userAttributes?.['custom:companyName'],
                  'custom:acknowledgement': options?.userAttributes?.['custom:acknowledgement']
                }
              }

            })
          },
          async validateCustomSignUp (formData) {
            // setCurrentFormData(current => ({ ...current, ...formData }))
            // setCurrentFormData(formData as unknown as CurrentFormDataProps)
            // console.log(formData)
            const errors: any = {}

            // Validate acknowledgement for terms and conditions
            if (!formData['custom:acknowledgement']) {
              errors.acknowledgement = 'You must agree to the Terms & Conditions'
            }

            // Validate the 'givenName' field for not being empty
            if (!formData.given_name) {
              errors.given_name = 'First name is required'
            }

            // Validate the 'familyName' field for not being empty
            if (!formData.family_name) {
              errors.family_name = 'Last name is required'
            }

            // Validate the 'companyName' field for not being empty
            if (!formData['custom:companyName']) {
              errors.companyName = 'Company name is required'
            }
            // Validate the 'companyName' field for not being empty
            if (!formData.phone_number) {
              errors.phone_number = 'Valid phone number is required'
            }

            // Validate the 'role' field for not being empty
            if (!formData['custom:role']) {
              errors.role = 'You must select your current role'
            }

            if (!formData.username.match(emailPattern)) {
              errors.username = 'Username is not a valid email'
            } else {
              const domain = formData.username.split('@')[1]
              if ([
                'gmail.com', 'yahoo.com', 'outlook.com', 'hotmail.com',
                'aol.com', 'mail.com', 'zoho.com', 'icloud.com',
                'gmx.com', 'yandex.com', 'tutanota.com', 'protonmail.com'
              ].includes(domain.toLowerCase())) {
                errors.username = 'Company email is required'
              }
            }
            // Return all accumulated errors
            return errors
          }
        }}
      ></Authenticator>
    </div>
  )
}

const roles = [
  'Business Analyst',
  'Chief Inf. Officer (CIO)',
  'Chief Inf. Security Officer (CISO)',
  'Chief Tech. Officer (CTO)',
  'Cloud Engineer',
  'Cloud Security Architect',
  'Cloud Security Engineer',
  'Cloud Security Analyst',
  'Cybersecurity Engineer',
  'Data Analyst',
  'Data Scientist',
  'Database Administrator',
  'DevOps Engineer',
  'Ethical Hacker',
  'Forensic Analyst',
  'Incident Responder',
  'Information Security Analyst',
  'Information Security Officer',
  'Infrastructure Engineer',
  'IT Auditor',
  'IT Compliance Analyst',
  'IT Director',
  'IT Manager',
  'K8s Administrator',
  'Mobile Application Developer',
  'Network Administrator',
  'Other',
  'Penetration Tester',
  'Project Manager',
  'Risk Analyst',
  'Security Architect',
  'Security Consultant',
  'Security Engineer',
  'Security Operations Center Analyst',
  'Site Reliability Engineer',
  'Software Engineer',
  'Systems Administrator',
  'Technical Support Specialist',
  'UX/UI Designer',
  'Web Developer'
]

export default RegisterPage
