import React from 'react'
const Footer = () => {
  return (
    <footer className="bg-black footer">
      <div className="container mx-auto px-4 text-center">
        <p className="text-white copyrightText">&copy; {new Date().getFullYear()} PreAuditAi, a CloudSec LLC Product. All rights reserved.</p>
        <a href={`${process.env.PUBLIC_URL}/privacy.html`} target="_blank" rel="noopener noreferrer">Privacy Notice</a>
      </div>
    </footer>
  )
}

export { Footer }
