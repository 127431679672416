export const awsScope = [
  {
    value: 'sf',
    text: 'Security foundations',
    controlIds: ['SEC01']
  },
  {
    value: 'iam',
    text: 'Identity and Access Management',
    controlIds: ['SEC02', 'SEC03']
  },
  {
    value: 'dc',
    text: 'Detection',
    controlIds: ['SEC04']
  },
  {
    value: 'ip',
    text: 'Infrastructure Protection',
    controlIds: ['SEC05', 'SEC06']
  },
  {
    value: 'dp',
    text: 'Data Protection',
    controlIds: ['SEC07', 'SEC08', 'SEC09']
  },
  {
    value: 'ir',
    text: 'Incident Response',
    controlIds: ['SEC10']
  },
  {
    value: 'as',
    text: 'Application Security',
    controlIds: ['SEC11']
  }
]
