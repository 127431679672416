import { USER_ACTION_TYPE_ENUMS } from 'config'
import type { UserAction, UserState } from 'types'

export const userReducer = (state: UserState, action: UserAction): UserState => {
  console.log('This is the state', state, action)
  switch (action.type) {
    case USER_ACTION_TYPE_ENUMS.AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: true,
        user: action.user
      }
    case USER_ACTION_TYPE_ENUMS.SIGN_OUT:
      return {
        ...state,
        isAuthenticated: false,
        user: null
      }
    case USER_ACTION_TYPE_ENUMS.AUTHENTICATION_ERROR:
      return {
        ...state,
        isAuthenticated: false,
        user: null,
        error: action.error
      }
    default:
      return state
  }
}
