import { createAWSAxiosInstance } from 'hooks'
import type {
  AWSDataStructure
} from 'types'
import { domain, resources } from 'config/endpointEnums'

const awsAxiosInstance = createAWSAxiosInstance()

interface CreatedAssessmentResponse {
  message: string
  assessment_id: string
}
async function createAwsAssessment (scope: AWSDataStructure): Promise<CreatedAssessmentResponse> {
  const path = `${domain.aws}${resources.createAssessment}`
  const response = await awsAxiosInstance.post<CreatedAssessmentResponse>(path, scope)
  console.log(response)
  return response.data
}

export {
  createAwsAssessment
}
