import React, { useEffect, useState } from 'react'
import {
  Select,
  TextInput,
  RadioGroup,
  Radio,
  Button,
  Container,
  Group,
  Title,
  Textarea
} from '@mantine/core'
import SocScopingForm from 'components/soc2/SocScopingForm'
import FedRampScopingForm from 'components/fedramp/FedRampScopingForm'
import CMMCScopingForm from 'components/cmmc/CMMCScopingForm'
import HIPAAScopingForm from 'components/hipaa/HIPAAScopingForm'
import PCIDSSScopingForm from 'components/pci_dss/PCIDSSScopingForm'
import GDPRScopingForm from 'components/gdpr/GDPRScopingForm'
import { frameworks } from 'data'
import AWSScopingForm from 'components/aws/AWSScopingForm'

interface FrameworkFormProps {
  framework: string
}

const FrameworkForm: React.FC<FrameworkFormProps> = ({ framework }) => {
  switch (framework) {
    case 'SOC 2':
      return (
        <div>
          <SocScopingForm/>
        </div>
      )
    case 'HIPAA':
      return (
        <div>
          <HIPAAScopingForm/>
        </div>
      )
    case 'CMMC':
      return (
        <div>
          <CMMCScopingForm/>
        </div>
      )
    case 'GDPR':
      return (<div><GDPRScopingForm/></div>)
    case 'PCI-DSS':
      return (
        <div>
          <PCIDSSScopingForm/>
        </div>
      )
    case 'AWS Cloud Adoption Security Pillars':
      return (
        <div>
          <AWSScopingForm/>
        </div>
      )
    case 'FedRAMP Moderate':
      return (
        <div>
          <FedRampScopingForm/>
        </div>
      )
    default:
      return <div>Select a compliance framework to see the form.</div>
  }
}

const ComplianceForm = () => {
  const [selectedFramework, setSelectedFramework] = useState<string>('')
  const [enabledPreAuditAiFrameworks, setEnabledPreAuditAiFrameworks] = useState<string[]>([])

  useEffect(() => {
    const enabledFrameworks = frameworks
      .filter((framework: { enabled: boolean, name: string }) => framework.enabled)
      .map((framework: { enabled: boolean, name: string }) => framework.name)

    setEnabledPreAuditAiFrameworks(enabledFrameworks)
  }, [])

  const handleChange = (value: string | null) => {
    if (value === null) {
      setSelectedFramework('')
    } else {
      setSelectedFramework(value)
    }
  }
  return (
    <Container>
      <Title order={2}>Select Compliance Framework</Title>
      <Select
        placeholder="Select Compliance Framework"
        data={enabledPreAuditAiFrameworks}
        value={selectedFramework}
        onChange={handleChange}
        required
        style={{ marginBottom: '5px' }}
      />
      <FrameworkForm framework={selectedFramework} />
    </Container>
  )
}

export default ComplianceForm
