import { message } from 'antd'
import { frameworkSampleData } from 'data'
import { useState, useEffect, useCallback } from 'react'
import { getDashboardData, initOnDemandScanData } from 'services'
import type { FrameworkData } from 'types'

interface UseReportComponentReturns {
  dashboardData: FrameworkData
  loading: boolean
  handleOnDemandRequest: () => Promise<void>
}

// eslint-disable-next-line @typescript-eslint/naming-convention
export const useReportComponent = (assessment_id: string): UseReportComponentReturns => {
  const [loading, setLoading] = useState(true)
  const [dashboardData, setDashboardData] = useState<FrameworkData>(
    {} as unknown as FrameworkData
  )

  const getJsonReport = useCallback(async () => {
    try {
      setLoading(true)
      const param = {
        assessment_id
      }
      if (assessment_id === 'simulation') {
        const data = frameworkSampleData as FrameworkData
        setDashboardData(data)
      } else {
        const data = await getDashboardData(param)
        setDashboardData(data)
      }
    } catch (error: any) {
      void message.error(
        error?.message ?? 'Unable to get report.'
      )
    } finally {
      setLoading(false)
    }
  }, [assessment_id])

  useEffect(() => {
    void getJsonReport()
  }, [getJsonReport])

  const handleOnDemandRequest = async () => {
    try {
      setLoading(true)
      const res = await initOnDemandScanData({ assessment_id })
      void message.success(res)
    } catch (error: any) {
      void message.error(error?.message ?? 'Unable to initiate on demand scan.')
    } finally {
      setLoading(false)
    }
  }
  return {
    dashboardData,
    loading,
    handleOnDemandRequest
  }
}
