import { Button } from 'antd'
import React, { useState } from 'react'
import {
  LineChart,
  Line,
  BarChart,
  Bar,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from 'recharts'
import type { Framework_Sum } from 'types'
interface TooltipDataItem {
  name: string
  value: string | number
  color?: string
}

interface CustomTooltipProps {
  active?: boolean
  payload?: TooltipDataItem[]
  label?: string | number
}

const formatDate = (unixTimestamp: string | number | Date) => {
  const date = new Date(unixTimestamp)
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  })
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload?.length) {
    return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
          <p className="label">{`Date: ${formatDate(Number(label))}`}</p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>{`${entry.name}: ${entry.value}`}</p>
          ))}
        </div>
    )
  }

  return null
}

interface ChartProps {
  data: Framework_Sum[]
}

const AssessmentChart: React.FC<ChartProps> = ({ data }) => {
  // State to toggle between chart types
  const [chartType, setChartType] = useState<'line' | 'bar' | 'area'>('line')

  return (
    <div>
      {/* Toggle Buttons */}
      <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px', marginRight: '2px', marginTop: '2px' }}>
        <Button
          onClick={() => {
            setChartType('line')
          }}
          style={{
            backgroundColor: '#4CAF50', // Green background
            color: 'white', // White text
            border: 'none', // No border
            cursor: 'pointer', // Cursor pointer
            borderRadius: '5px' // Rounded corners
          }}
        >
          Line Chart
        </Button>
        <Button
          onClick={() => {
            setChartType('bar')
          }}
          style={{
            backgroundColor: '#008CBA', // Blue background
            color: 'white', // White text
            // padding: '10px 20px', // Padding around the text
            border: 'none', // No border
            cursor: 'pointer', // Cursor pointer
            borderRadius: '5px' // Rounded corners
          }}
        >
          Bar Chart
        </Button>
        <Button
          onClick={() => {
            setChartType('area')
          }}
          style={{
            backgroundColor: '#6dcfe8', // Red background
            color: 'white', // White text
            // padding: '10px 20px', // Padding around the text
            border: 'none', // No border
            cursor: 'pointer', // Cursor pointer
            borderRadius: '5px' // Rounded corners
          }}
        >
          Area Chart
        </Button>
      </div>

      {/* Conditional Rendering of Charts */}
      {chartType === 'line' && (
        <ResponsiveContainer style={{ marginTop: '50px' }} width="100%" height={300}>
          <LineChart data={data} margin={{ top: 5, right: 30, left: 10, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" tickFormatter={formatDate} />
            <YAxis />
            <Tooltip content={<CustomTooltip />} />
            <Legend />
            <Line type="monotone" dataKey="passed" stroke="#82ca9d" activeDot={{ r: 8 }} />
            <Line type="monotone" dataKey="failed" stroke="#ff7300" />
            <Line type="monotone" dataKey="total" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      )}

      {chartType === 'bar' && (
        <div>
          <h2 style={{ textAlign: 'center' }}>Trends: Pass, Fail & Totals</h2>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              width={800}
              height={300}
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={formatDate} />
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Bar dataKey="passed" fill="#82ca9d" />
              <Bar dataKey="failed" fill="#ff7300" />
              <Bar dataKey="total" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>
      )}

      {chartType === 'area' && (
        <div>
          <h2 style={{ textAlign: 'center' }}>Trends: Pass, Fail & Totals</h2>
          <ResponsiveContainer width="100%" height={300}>
            <AreaChart
              width={800}
              height={300}
              data={data}
              margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="date" tickFormatter={formatDate}/>
              <YAxis />
              <Tooltip content={<CustomTooltip />} />
              <Legend />
              <Area
                type="monotone"
                dataKey="passed"
                stroke="#82ca9d"
                fill="#82ca9d"
              />
              <Area
                type="monotone"
                dataKey="failed"
                stroke="#ff7300"
                fill="#ff7300"
              />
              <Area
                type="monotone"
                dataKey="total"
                stroke="#8884d8"
                fill="#8884d8"
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
      )}
    </div>
  )
}

export { AssessmentChart }
