import { useState } from 'react'
import { type NOTIFICATION_DT } from 'types'

const NOTIFICATION_KEY = 'notifications'

export const useNotifications = () => {
  const [notifications, setNotifications] = useState<NOTIFICATION_DT[]>(() => {
    const storedNotifications = localStorage.getItem(NOTIFICATION_KEY)
    return storedNotifications ? JSON.parse(storedNotifications) : []
  })

  const addNotification = (notif: NOTIFICATION_DT) => {
    const updatedNotifications = [...notifications, notif]
    setNotifications(updatedNotifications)
    localStorage.setItem(NOTIFICATION_KEY, JSON.stringify(updatedNotifications))
  }

  const clearSelectedNotifications = (ids?: number[]) => {
    let updatedNotifications = notifications
    if (ids && ids.length > 0) {
      updatedNotifications = notifications.filter(n => !ids.includes(n.id))
    }
    setNotifications(updatedNotifications)
    localStorage.setItem(NOTIFICATION_KEY, JSON.stringify(updatedNotifications))
  }
  const clearAllNotifications = () => {
    let updatedNotifications = notifications
    updatedNotifications = []
    setNotifications(updatedNotifications)
    localStorage.setItem(NOTIFICATION_KEY, JSON.stringify(updatedNotifications))
  }
  return {
    notifications,
    addNotification,
    clearSelectedNotifications,
    clearAllNotifications
  }
}
