import React, { useState } from 'react'
import { Form, Radio, Button, Row, Col, Input, Space, Checkbox } from 'antd'
import { cmmcScope } from 'data' // Assume cmmcScope data is imported from a separate file
import { useNavigate } from 'react-router-dom'

const CMMCScopingForm = () => {
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const [loading, setLoading] = useState(false)

  const onFinish = async (values: any) => {
    console.log('Received values:', values)
  }

  return (
    <div className="scoping-form">
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <h2>Scope the CMMC Domains to be Assessed</h2>
        <Form.Item
          name="title"
          label="Title for the Assessment"
          rules={[{ required: true, message: 'Please input the title for the assessment!' }]}
        >
          <Input placeholder="Enter assessment title" />
        </Form.Item>

        <Form.Item
          name="maturityLevel"
          label="Target CMMC Maturity Level"
          rules={[{ required: true, message: 'Please select the target CMMC maturity level!' }]}
        >
          <Radio.Group>
            <Radio.Button value="1">Level 1</Radio.Button>
            <Radio.Button value="2">Level 2</Radio.Button>
            <Radio.Button value="3">Level 3</Radio.Button>
            <Radio.Button value="4">Level 4</Radio.Button>
            <Radio.Button value="5">Level 5</Radio.Button>
          </Radio.Group>
        </Form.Item>

        <Form.Item label="Select CMMC Domains in Scope">
          <Space direction="vertical">
            {cmmcScope.map((domain: { value: any, text: any }) => (
              <Row key={domain.value} gutter={16} align="middle">
                <Col span={24}>
                  <Form.Item
                    name={['domains', domain.value, 'inScope']}
                    valuePropName="checked"
                    initialValue={false}
                  >
                    <Checkbox>{domain.text}</Checkbox>
                  </Form.Item>
                </Col>
              </Row>
            ))}
          </Space>
        </Form.Item>

        <Form.Item
          name="assessmentFrequency"
          label="Assessment Frequency"
          rules={[{ required: true, message: 'Please select the assessment frequency!' }]}
        >
          <Radio.Group>
            <Radio value="annual">Annual</Radio>
            <Radio value="semiannual">Semi-Annual</Radio>
            <Radio value="quarterly">Quarterly</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading}>
            Create Assessment
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
}

export default CMMCScopingForm
