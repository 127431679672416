export const scope = [
  {
    value: 'cc.1',
    text: 'Control environment (CC1)'
  },
  {
    value: 'cc.2',
    text: 'Communication and Information (CC2)'
  },
  {
    value: 'cc.3',
    text: 'Risk Assessment (CC3)'
  },
  {
    value: 'cc.4',
    text: 'Monitoring Activities (CC4)'
  },
  {
    value: 'cc.5',
    text: 'Control Activities (CC5)'
  },
  {
    value: 'cc.6',
    text: 'Logical and Physical Access Controls (CC6)'
  },
  {
    value: 'cc.7',
    text: 'System Operations (CC7)'
  },
  {
    value: 'cc.8',
    text: 'Change Management (CC8)'
  },
  {
    value: 'cc.9',
    text: 'Risk Mitigation (CC9)'
  }
]
