import { createGdprAxiosInstance } from 'hooks'
import { domain, resources } from 'config/endpointEnums'

const gdprAxiosInstance = createGdprAxiosInstance()

interface CreatedAssessmentResponse {
  message: string
  assessment_id: string
}
async function createGdprAssessment (title: string): Promise<CreatedAssessmentResponse> {
  const path = `${domain.gdpr}${resources.createAssessment}`
  const response = await gdprAxiosInstance.post<CreatedAssessmentResponse>(path, title)
  console.log(response)
  return response.data
}

export {
  createGdprAssessment
}
