import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from 'components/core/auth/ProtectedRoute'
import { ROUTES } from 'config'
import PasswordReset from 'components/core/auth/PasswordReset'
import { VerifyEmailPage } from 'components/core/auth/VerifyEmailPage'
import NotFound from 'components/core/Utils/NotFoundPage'
import ErrorPage from 'components/core/Utils/ErrorPage'
import RegisterPage from 'components/core/auth/RegisterPage'
// import MainPortal from 'components/core/OverviewPage/OverviewPage'
import MainPortal from 'components/core/MainPortal'
import SocAssessmentDashboard from 'components/core/Dashboard/AssessmentDashboard'
import SocAssessmentDetails from 'components/core/AssessmentDetails'
import PrivacyPolicy from 'components/core/Utils/PrivacyPolicy'
import { SubscriptionPage } from 'components/core/SubscriptionPage'
const AppRoutes: React.FC = () => (
    <Routes>
        <Route path={ROUTES.SUBSCRIPTION} element={<SubscriptionPage />} />
        <Route path={ROUTES.HOME} element={<RegisterPage initiatePage='signIn'/>} />
        <Route path={ROUTES.LOGIN} element={<RegisterPage initiatePage='signIn'/>} />
        <Route path={ROUTES.REGISTER} element={<RegisterPage initiatePage='signUp'/>} />
        <Route path={ROUTES.RESET_PASSWORD} element={<PasswordReset />} />
        <Route path={ROUTES.ERROR} element={<ErrorPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route
            path={ROUTES.VERIFY_EMAIL}
            element={
                    <ProtectedRoute >
                        <VerifyEmailPage />
                    </ProtectedRoute>
            }
        />
        <Route path={ROUTES.NOTFOUND} element={<NotFound />} />
        <Route path={`${ROUTES.DASHBOARD}/:id`}element={<SocAssessmentDashboard />} />
        <Route path={`${ROUTES.ASSESSMENTS}/:id`}element={<SocAssessmentDetails />} />
        <Route
            path={ROUTES.OVERVIEW}
            element={
                    <ProtectedRoute >
                        <MainPortal />
                    </ProtectedRoute>
            }
        />
    </Routes>
)

export { AppRoutes }
