import React, { useEffect, useState } from 'react'
import { Table, Input, Button, Space, Image, Modal, Spin, Tag } from 'antd'
import { Alert } from '@mantine/core'
import { SearchOutlined, CommentOutlined } from '@ant-design/icons'
import placeholderIconUrl from '../../../assets/logo/favicon-black.png'
import { type BottomSectionSummaryReportItem } from 'types'
import { askQBiBot } from 'services'
import MobileView from '../../../hooks/core/mobileView'
import { InfoOutlined } from '@mui/icons-material'
interface DataRecord {
  row_key: string
  key: string
  Domain: string
  ServiceName: string
  serviceIconUrl: string
  Expectation: string
  Evaluation: string
  Risk: string
  Region: string
  AccountId: string
  Severity: string
}

interface additinalExpandedData {
  ResourceArn: string
  RecommendationText: string
  RecommendationUrl: string
}

interface FilterDropdownParams {
  setSelectedKeys: (keys: string[]) => void
  selectedKeys: string[]
  confirm: () => void
  clearFilters: () => void
}

interface ComplianceDashboardBottomSectionProps {
  data: BottomSectionSummaryReportItem[]
  assessmentId: string
}
const getColumnSearchProps = (dataIndex: keyof DataRecord): any => ({
  filterDropdown: ({
    setSelectedKeys,
    selectedKeys,
    confirm,
    clearFilters
  }: FilterDropdownParams) => (
    <div style={{ padding: 8 }}>
      <Input
        placeholder={`Search ${dataIndex}`}
        value={selectedKeys[0]}
        onChange={(e) => {
          setSelectedKeys(e.target.value ? [e.target.value] : [])
        }}
        onPressEnter={() => {
          confirm()
        }}
        style={{ marginBottom: 8, display: 'block' }}
      />
      <Space>
        <Button
          type="primary"
          onClick={() => {
            confirm()
          }}
          icon={<SearchOutlined />}
          size="small"
          style={{ width: 90 }}
        >
          Search
        </Button>
        <Button
          onClick={() => {
            clearFilters()
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered: any) => (
    <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
  ),
  onFilter: (
    value: string,
    record: Record<string, { toString: () => string }>
  ) =>
    record[dataIndex]
      ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
      : '',
  onFilterDropdownVisibleChange: (visible: boolean) => {
    if (visible) {
      setTimeout(() => {
        const input = document.querySelector('.ant-input') as HTMLInputElement
        if (input) input.focus()
      }, 100)
    }
  }
})

const ComplianceDashboardBottomSection: React.FC<
ComplianceDashboardBottomSectionProps
> = ({ data, assessmentId }) => {
  const [loadingStates, setLoadingStates] = useState<Record<any, boolean>>({})
  async function askingBiBot (record: DataRecord) {
    setLoadingStates(prev => ({ ...prev, [record.row_key]: true }))
    const inquiry = `This is the expected control guideline: ${record.Expectation}. \n\nHow do I remediate this finding?`
    const response = await askQBiBot({ assessment_id: assessmentId, inquiry })
    setLoadingStates(prev => ({ ...prev, [record.row_key]: false }))
    Modal.confirm({
      title: 'BiBot Says...',
      content: `${response}`,
      okText: 'Done',
      cancelButtonProps: { style: { display: 'none' } }
    })
  }
  const columns = [
    {
      title: 'Domain',
      dataIndex: 'Domain',
      key: 'Domain',
      sorter: (a: { Domain: string }, b: { Domain: any }) =>
        a.Domain.localeCompare(b.Domain),
      render: (text: string | undefined, record: any) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          {text?.toUpperCase()}
        </div>
      ),
      ...getColumnSearchProps('Domain')
    },
    {
      title: 'AccountId',
      dataIndex: 'AccountId',
      key: 'AccountId',
      sorter: (a: { AccountId: string }, b: { AccountId: any }) =>
        a.AccountId.localeCompare(b.AccountId),
      ...getColumnSearchProps('AccountId')
    },
    {
      title: 'Region',
      dataIndex: 'Region',
      key: 'Region',
      sorter: (a: { Region: string }, b: { Region: any }) =>
        a.Region.localeCompare(b.Region),
      ...getColumnSearchProps('Region')
    },
    {
      title: 'Service Name',
      dataIndex: 'ServiceName',
      key: 'ServiceName',
      render: (text: string | undefined, record: { serviceIconUrl: any }) => (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Image
            src={record.serviceIconUrl || placeholderIconUrl}
            alt={text}
            style={{
              width: 20,
              height: 20,
              marginRight: 8,
              borderRadius: '50%'
            }}
            fallback={placeholderIconUrl}
          />
          {text?.toUpperCase()}
        </div>
      ),
      ...getColumnSearchProps('ServiceName')
    },
    {
      title: 'Expectation',
      dataIndex: 'Expectation',
      key: 'Expectation',
      sorter: (a: { Expectation: string }, b: { Expectation: any }) =>
        a.Expectation.localeCompare(b.Expectation),
      ...getColumnSearchProps('Expectation')
    },
    {
      title: 'Risk',
      dataIndex: 'Risk',
      key: 'Risk',
      sorter: (a: { Risk: string }, b: { Risk: any }) =>
        a.Risk.localeCompare(b.Risk),
      ...getColumnSearchProps('Risk')
    },
    {
      title: 'Evaluation',
      dataIndex: 'Evaluation',
      key: 'Evaluation',
      filters: [
        { text: 'PASS', value: 'pass' },
        { text: 'FAIL', value: 'fail' },
        { text: 'INFO', value: 'info' }
      ],
      onFilter: (value: string, record: DataRecord) => record.Evaluation.toString().toLowerCase() === value.toLowerCase(),
      render: (evaluation: string) => {
        let color = 'geekblue'
        if (evaluation === 'PASS') {
          color = 'green'
        } else if (evaluation === 'FAIL') {
          color = 'volcano'
        } else if (evaluation === 'INFO') {
          color = 'blue'
        }
        return (
          <Tag color={color} key={evaluation}>
            {evaluation.toUpperCase()}
          </Tag>
        )
      }
    },
    {
      title: 'Severity',
      dataIndex: 'Severity',
      key: 'Severity',
      render: (text: string, record: DataRecord) => {
        let color
        switch (text) {
          case 'critical':
            color = 'red'
            break
          case 'high':
            color = 'volcano'
            break
          case 'medium':
            color = 'orange'
            break
          case 'low':
            color = 'green'
            break
          default:
            color = 'default'
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Tag color={color} key={text}>
              {text?.toUpperCase()}
            </Tag>
          </div>
        )
      },
      filters: [
        { text: 'CRITICAL', value: 'critical' },
        { text: 'HIGH', value: 'high' },
        { text: 'MEDIUM', value: 'medium' },
        { text: 'LOW', value: 'low' }
      ],
      onFilter: (value: string, record: DataRecord) => record.Severity.toString().toLowerCase() === value.toLowerCase()
    },
    {
      title: 'Ask BiBot',
      dataIndex: 'Ask BiBot',
      key: 'Ask BiBot',
      render: (text: string | undefined, record: DataRecord) => {
        const isLoading = loadingStates[record.row_key]
        return (
          <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }} onClick={async () => {
            await askingBiBot(record)
          }}>
            {isLoading ? <Spin /> : <CommentOutlined style={{ marginRight: 8, color: '#1890ff', width: '50px', height: '50px' }} />}
          </div>
        )
      }
    }
  ]

  const expandedRowRender = (record: additinalExpandedData) => {
    return (
      <div>
      <p
        style={{
          margin: 0,
          paddingBottom: '10px',
          borderBottom: '1px solid #e8e8e8'
        }}
      >
        <strong>Resource ARN:</strong> {record.ResourceArn}
      </p>
      <p
        style={{
          margin: 0,
          paddingBottom: '10px',
          borderBottom: '1px solid #e8e8e8'
        }}
      >
        <strong>Recommendation:</strong> {record.RecommendationText}
      </p>
      <p style={{ margin: 0, paddingBottom: '10px' }}>
        <strong>More Info:</strong>{' '}
        <a
          href={record.RecommendationUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {record.RecommendationUrl}
        </a>
      </p>
    </div>
    )
  }
  return (
    <>
    { MobileView()
      ? <Alert variant="light" color="blue" title="Table not available" icon={<InfoOutlined/>}>
     Please switch to desktop to be able to view table
    </Alert>
      : <Table
        rowKey={record => record.row_key}
      columns={columns}
      dataSource={data}
      rowSelection={{
        type: 'checkbox'
      }}
      expandable={{ expandedRowRender }}
    />}

    </>

  )
}

export { ComplianceDashboardBottomSection }
