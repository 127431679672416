// AdminPortal.tsx
import React, { useContext, useState } from 'react'
import {
  Avatar,
  Layout,
  Menu,
  message
} from 'antd'
import {
  OrderedListOutlined,
  UserOutlined,
  LogoutOutlined
} from '@ant-design/icons'
import { AppContext, UserContext } from 'context'
import { CreateNewFolderOutlined, CreditCardOutlined, Dashboard, FileCopySharp, Notifications, Settings, SubscriptionsOutlined } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom'
import { appStateEnums } from 'config'
import { signOut, createSubscribeSession } from 'services'
import ComplianceForm from './scoping/ComplianceForm'
import AssessmentList from './AssessmentList'
import NotificationsPage from 'components/core/Utils/NotificationsPage'
import SettingsPage from 'components/core/Utils/SettingsPage'
import SocAssessmentDashboard from './Dashboard/AssessmentDashboard'
import { SubscriptionPage } from './SubscriptionPage'
import { Footer } from './Utils'
const { Content, Sider } = Layout
const COMPONENT_MAP: any = {
  // addReport: <AddReport />,
  // createCognitoUserGroup: <CreateCognitoUserGroup />,
  // listReports: <ListAdminReports />,
  // listUsers: <ListUsers />,
  // createUser: <CreateUser />,
  // uploadReports: <UploadReports />,` { /* <StripePricingTable ptId={stripe.ptId} pk={stripe.pk}/> */ }
  assessmentDashboard: <SocAssessmentDashboard />,
  settings: <SettingsPage />,
  notifications: <NotificationsPage />,
  assessmentList: <AssessmentList />,
  createAssessment: <ComplianceForm />,
  subscription: <SubscriptionPage/>
}

const MainPortal: React.FC = () => {
  const navigate = useNavigate()
  const { state: appState, dispatch: appDispatch } = useContext(AppContext)
  const toggleCollapsed = function (collapsed: boolean) {
    appDispatch({ type: appStateEnums.COLLAPSE, collapsed })
  }
  const { state } = useContext(UserContext)
  const { user } = state

  const [selectedKey, setSelectedKey] = useState<string>('assessmentList')
  const onMenuClick = (key: string) => {
    setSelectedKey(key)
  }
  const getInitials = (givenName: string, familyName: string) => {
    return `${givenName?.charAt(0) || ''}${familyName?.charAt(0) || ''}`.toUpperCase()
  }

  const menuItems = [
    {
      label: 'Assessments',
      key: 'assessments',
      icon: <FileCopySharp />,
      children: [
        {
          label: 'Create',
          key: 'createAssessment',
          icon: <CreateNewFolderOutlined />
        },
        {
          label: 'List',
          key: 'assessmentList',
          icon: <OrderedListOutlined />
        }
      ]
    },
    // {
    //   label: 'Settings',
    //   key: 'settings',
    //   icon: <Settings />
    // },
    {
      label: 'Notifications',
      key: 'notifications',
      icon: <Notifications />
    },
    {
      label: 'Subscription',
      key: 'subscription',
      icon: <CreditCardOutlined />
    },
    {
      label: 'Logout',
      key: 'logOut',
      icon: <LogoutOutlined />,
      onClick: async () => {
        try {
          await signOut()
        } catch (error: any) {
          void message.error(error?.message || 'There was an error signing out')
        }
      }
    }
  ]
  return (
    <Layout style={{ minHeight: '100vh' }}>
      <Layout>
        <Sider
          width={300}
          style={{
            // borderTopRightRadius: '20px',
            // borderBottomRightRadius: '20px',
            overflow: 'hidden'
          }}
          collapsible
          collapsed={appState.collapsed}
          onCollapse={toggleCollapsed}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: '10px',
              gap: '10px'
            }}
          >
            <Avatar
              src={user?.avatarUrl}
              size={32}
              icon={
                !user?.avatarUrl &&
                (appState.collapsed
                  ? (
                      getInitials(user?.given_name ?? '', user?.family_name ?? '')
                    )
                  : (
                  <UserOutlined />
                    ))
              }
            />
            {!appState.collapsed && (
              <span style={{ color: 'white' }}>{`${user?.given_name} ${user?.family_name}`}</span>
            )}
          </div>
          <Menu
            theme={appState.theme}
            mode="inline"
            defaultSelectedKeys={['createUser']}
            className="menu"
            items={menuItems}
            onClick={({ key }) => {
              onMenuClick(key)
            }}
          />
        </Sider>
        <Layout className="content-layout">
          <Content className="site-layout-background">
            {COMPONENT_MAP[selectedKey] || (
              <div className="not-implemented">Not implemented</div>
            )}
          </Content>
        </Layout>
      </Layout>
      <Footer/>
    </Layout>
  )
}

export default MainPortal
