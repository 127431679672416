import React from 'react'
import { Typography, Progress } from 'antd'
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  VerticalAlignMiddleOutlined
} from '@ant-design/icons'
import { Search } from '@mui/icons-material'
import type { HeaderSummaryData } from 'types'
import { Card, Paper } from '@mantine/core'
import './ComplianceDashboardHeader.css'
import { View } from '@aws-amplify/ui-react'

const { Title, Text } = Typography

interface HeadlinerCardProps {
  title: string
  value: string
  trend: number
  trendText: string
  icon: JSX.Element
  color: string
  arrow: JSX.Element
}

interface ComplianceDashboardBottomSectionProps {
  data: HeaderSummaryData
}

const HeadlinerCard: React.FC<HeadlinerCardProps> = ({
  title,
  value,
  trend,
  trendText,
  icon,
  color,
  arrow
}) => {
  return (
    <>
      <Card
        // shadow="sm"
        withBorder
        component="a"
        className="card"
        style={{ width: '19%', minWidth: 300, borderRadius: '8px' }}
      >
        <Card.Section
          style={{
            display: 'flex',
            justifyContent: 'space-around',
            padding: '8px'
          }}
        >
          <div style={{ fontSize: '2em', color }}>{icon}</div>
          <div style={{ marginBottom: '1px' }}>
            <Title level={5}>{title}</Title>
            <Title style={{ marginBottom: '0px' }} level={3}>
              {value}
            </Title>
            <View style={{ flexDirection: 'row', alignItems: 'center' }}>
              <Text style={{ color }}>{arrow}</Text>
              <Text style={{ color }}>
                {`${Math.abs(trend)}% ${trendText}`}
              </Text>
            </View>
          </div>
        </Card.Section>
      </Card>
    </>
  )
}

const ComplianceDashboardHeader: React.FC<
ComplianceDashboardBottomSectionProps
> = ({ data }) => {
  return (
    <div
      className="mobile-view"
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between'
      }}
    >
      <HeadlinerCard
        title="Compliance Score"
        value={`${Math.round(data.compliance_score * 100)}%`}
        trend={Math.round(data.compliance_trend)}
        trendText="Since last audit"
        icon={
          <Progress
            style={{ marginTop: '4px' }}
            type="circle"
            percent={Math.round(data.compliance_score * 100)}
            size={80}
          />
        }
        color={data.compliance_trend >= 0 ? 'green' : 'red'}
        arrow={
          data.compliance_trend === 0
            ? (
            <VerticalAlignMiddleOutlined />
              )
            : data.compliance_trend > 0
              ? (
            <ArrowUpOutlined />
                )
              : (
            <ArrowDownOutlined />
                )
        }
      />
      <HeadlinerCard
        title="Critical Findings"
        value={`${Math.round(data.critical_findings)}`}
        trend={Math.round(data.critical_findings_trend)}
        trendText={
          data.critical_findings_trend === 0 ? 'Steady' : data.critical_findings_trend > 0 ? 'Reduced' : 'Deteriorating'
        }
        icon={<span style={{ fontSize: '2em' }}>🔺</span>}
        color={data.critical_findings_trend <= 0 ? 'red' : 'green'}
        arrow={
          data.critical_findings_trend === 0
            ? (
            <VerticalAlignMiddleOutlined />
              )
            : data.critical_findings_trend < 0
              ? (
            <ArrowUpOutlined />
                )
              : (
            <ArrowDownOutlined />
                )
        }
      />
      <HeadlinerCard
        title="New Findings"
        value={`${Math.round(data.new_findings)}`}
        trend={Math.round(data.new_findings_trend)}
        trendText={
          data.new_findings_trend === 0 ? 'Steady' : data.new_findings_trend < 0 ? 'Improving' : 'Deteriorating'
        }
        icon={
          <Search style={{ color: 'grey', height: '80px', width: '100px' }} />
        }
        color={data.new_findings_trend <= 0 ? 'green' : 'red'}
        arrow={
          data.new_findings_trend === 0
            ? (
            <VerticalAlignMiddleOutlined />
              )
            : data.new_findings_trend > 0
              ? (
            <ArrowUpOutlined />
                )
              : (
            <ArrowDownOutlined />
                )
        }
      />
      <HeadlinerCard
        title="Remediation Progress"
        value={`${Math.round(data.remediation_progress)}`}
        trend={Math.round(data.remediation_progress_trend)}
        trendText={
          data.remediation_progress_trend === 0 ? 'Steady' : data.remediation_progress_trend < 0 ? 'Improving' : 'Deteriorating'
        }
        icon={
          <Progress
            style={{ marginTop: '4px' }}
            type="circle"
            percent={Math.round(data.remediation_progress * 100)}
            status="active"
            width={80}
          />
        }
        color={data.remediation_progress_trend <= 0 ? 'green' : 'red'}
        arrow={
          data.remediation_progress_trend === 0
            ? (
            <VerticalAlignMiddleOutlined />
              )
            : data.remediation_progress_trend < 0
              ? (
            <ArrowUpOutlined />
                )
              : (
            <ArrowDownOutlined />
                )
        }
      />
      <HeadlinerCard
        title="Risk Exposure"
        value={data.risk_exposure.risk_exposure_description}
        trend={Math.round(data.risk_exposure_trend)}
        trendText={data.risk_exposure_trend === 0 ? 'Steady' : data.risk_exposure_trend > 0 ? 'Improved' : 'Deteriorated'}
        icon={<span style={{ fontSize: '2em' }}>⚖️</span>}
        color={data.risk_exposure_trend >= 0 ? 'green' : 'red'}
        arrow={
          data.risk_exposure_trend === 0
            ? (
            <VerticalAlignMiddleOutlined />
              )
            : data.risk_exposure_trend < 0
              ? (
            <ArrowUpOutlined />
                )
              : (
            <ArrowDownOutlined />
                )
        }
      />
    </div>
  )
}

export { ComplianceDashboardHeader }
