export const frameworks = [
  {
    enabled: true,
    name: 'AWS Cloud Adoption Security Pillars'
  },
  {
    enabled: false,
    name: 'CMMC'
  },
  {
    enabled: true,
    name: 'GDPR'
  },
  {
    enabled: true,
    name: 'HIPAA'
  },
  {
    enabled: true,
    name: 'PCI-DSS'
  },
  {
    enabled: true,
    name: 'SOC 2'
  },
  {
    enabled: true,
    name: 'FedRAMP Moderate'
  }
]
