import { Form, List, Card, Tag, Button, message, Space } from 'antd'
import { Link } from 'react-router-dom'
import PageTitle from 'components/core/Utils/PageTitle'
import { useCallback, useEffect, useState } from 'react'
import { getAllAssessments } from 'services'
import { useNotifications } from 'hooks'
import { ROUTES } from 'config'
import type { AssessmentType } from 'types'
import { frameworkDetector, timestampDisplay } from 'utils'
import './AssessmentList.css'

const getStatusTagColor = (status: any) => {
  switch (status) {
    case 'In Progress':
      return 'gold'
    case 'Under Evaluation':
      return 'blue'
    case 'Report Ready':
      return 'green'
    case 'Not Started':
      return 'red'
    default:
      return 'default'
  }
}

const AssessmentList = () => {
  const { addNotification } = useNotifications()
  const [form] = Form.useForm()
  // const [loading, setLoading] = useState(false)
  const [loadingStates, setLoadingStates] = useState<Record<string, boolean>>(
    {}
  )
  const [assessments, setAssessments] = useState<AssessmentType[]>([])

  const loadAssessments = useCallback(async () => {
    try {
      setLoadingStates((prev) => ({ ...prev, form_list_load: true }))
      const remoteAssessments = await getAllAssessments()
      if (remoteAssessments.assessments.length > 0) {
        setAssessments(remoteAssessments.assessments)
      }
    } catch (error: any) {
      addNotification({
        id: Date.now(),
        type: 'error',
        message: `${error}`,
        timestamp: Date.now()
      })
      void message.error(error.message ?? 'Error getting the assessments')
    } finally {
      setLoadingStates((prev) => ({ ...prev, form_list_load: false }))
    }
  }, [])

  useEffect(
    function () {
      void loadAssessments()
    },
    [loadAssessments]
  )

  return (
    <>
   <PageTitle text="Assessments Dashboard" />
<List loading={loadingStates.form_list_load}
    grid={{
      gutter: 16,
      xs: 1,
      sm: 1,
      md: 1,
      lg: 2,
      xl: 3,
      xxl: 3
    }}
    dataSource={assessments}
    renderItem={(item) => (
      <List.Item>
        <div className='card'>
          <div className='card-title'>
            <div><h4>{item.title}</h4></div>
             <div> <Tag color={getStatusTagColor(item.status)}>{item.status}</Tag></div>
          </div>
          <div className='card-content'>
          <Tag key={frameworkDetector(item.assessment_type)} color={'blue'}>
                      {frameworkDetector(item.assessment_type).toUpperCase()}
                    </Tag>
          </div>

          <div className='card-content'>
              <p>
              <span>{item?.scoped_items?.length > 0 && (
                <p>
                  <strong>Scoped Domains:</strong>{' '}
                  <span>
                    {item.scoped_items.map((tscItem) => (
                      <Tag key={tscItem} color={'blue'}>
                        {tscItem.toUpperCase()}
                      </Tag>
                    ))}
                  </span>
                 </p>
              )}</span>
              <p>
                <strong>Coverage (Policy Review | Technical Scan):</strong>{' '}
                <span>
                  {
                    <Tag key={item.pot} color={'grey'}>
                      {item.pot.toUpperCase()}
                    </Tag>
                  }
                </span>
              </p>
              <p>
                <strong>Created On:</strong> <span>{timestampDisplay(item.created_on)}</span>
              </p>
                <strong>Last Updated On:</strong>{' '}
                <span>{timestampDisplay(item.last_modified_on)}</span>
               <p>
               <strong>Owner:</strong>{' '}
                <Link to={`/profile/${item.owner_name}`}>
                  {item.owner_name}
                </Link>
                </p>
              </p>
          </div>
          <div className='card-footer'>
                  <Link to={`${ROUTES.ASSESSMENTS}/${item.assessment_id}`}>
                  <button className='card-button'>
                    View/Update
                    </button>
                  </Link>
                  <Link to={`${ROUTES.DASHBOARD}/${item.assessment_id}`}>
                  <button className='card-button'>
                    Dashboard
                    </button>
                  </Link>
               {/* {(process.env.NODE_ENV === 'development' || process.env.REACT_APP_ENV === 'dev') &&
                  <Link to={`${ROUTES.DASHBOARD}/simulation`}>
                    <button className='card-button'>
                    Simulated Dashboard
                    </button>
                  </Link>
               } */}
                {/* <Button
                  danger
                  loading={loadingStates.pause_restart}
                  disabled={item.status !== 'Not Started'}
                  onClick={async () => {
                    // await handleDeleteAssessment(item)
                  }}
                >
                  Delete
                </Button> */}
          </div>
        </div>
      </List.Item>
    )}
  />
 </>
  )
}

export default AssessmentList
