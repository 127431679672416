// import { socExec } from '../../data/soc_exec'
import React from 'react'
import { useParams } from 'react-router-dom'
import {
  Spin,
  Divider
} from 'antd'
import { Space } from '@mantine/core'
import { useReportComponent } from 'hooks'
import { ComplianceDashboardBottomSection, ComplianceDashboardMidSection, ComplianceDashboardHeader } from '.'
import { NavBar } from '../NavBar/NavBar'
import NotFound from 'components/core/Utils/NotFoundPage'

const AssessmentDashboard: React.FC = () => {
  const { id } = useParams()
  if (!id) {
    return (
      <>
        <NotFound/>
      </>
    )
  }
  const {
    loading,
    dashboardData,
    handleOnDemandRequest
  } = useReportComponent(id)

  if (loading) {
    return (
      <>
        <Spin style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh'
        }} />
      </>
    )
  }
  return (
    <div style={{ backgroundColor: '#f1f1f1', paddingLeft: '24px', paddingRight: '24px' }}>
      <NavBar mainContent={
        <>
        {dashboardData?.header_summary_data && <ComplianceDashboardHeader data={dashboardData.header_summary_data}/>}
         <Space h="sm"/>
      {dashboardData?.mid_section_summary_report && <ComplianceDashboardMidSection data={dashboardData.mid_section_summary_report}/>}
      <Divider/>
      {dashboardData?.bottom_section_summary_report && <ComplianceDashboardBottomSection data={dashboardData.bottom_section_summary_report} assessmentId={id} />}
      <div style={{ marginBlock: '40px', alignContent: 'center', textAlign: 'center' }}>
     Contact us: <span style={{ textDecoration: 'underline' }}>preauditai@thecloudsecllc.com</span>
     </div>
        </>
      } title={dashboardData?.assessment_data?.title ?? 'No report available'} onDemandFunction={async function (): Promise<void> {
        void handleOnDemandRequest()
      } } />
    </div>
  )
}

export default AssessmentDashboard
