const isProd = process.env.REACT_APP_ENV === 'prod'

export const APP_DATA = {
  APP_NAME: isProd ? 'PreAuditAi' : 'PreAuditAi-Dev'
}

const baseUrls = {
  core: isProd ? 'https://api.app.preauditai.com/v0' : 'https://api.app.dev.preauditai.com/v0',
  soc: isProd ? 'https://api.soc.app.preauditai.com/v0' : 'https://api.soc.app.dev.preauditai.com/v0',
  aws: isProd ? 'https://api.aws.app.preauditai.com/v0' : 'https://api.aws.app.dev.preauditai.com/v0',
  // bibot: isProd ? 'https://bibot.thespuka.com/api/v1' : 'https://api.bibot.com/v1',
  fedrampmod: isProd ? 'https://api.fedrampmod.app.preauditai.com/v0' : 'https://api.fedrampmod.app.dev.preauditai.com/v0',
  gdpr: isProd ? 'https://api.gdpr.app.preauditai.com/v0' : 'https://api.gdpr.app.dev.preauditai.com/v0',
  hipaa: isProd ? 'https://api.hipaa.app.preauditai.com/v0' : 'https://api.hipaa.app.dev.preauditai.com/v0',
  pciDss: isProd ? 'https://api.pci-dss.app.preauditai.com/v0' : 'https://api.pci-dss.app.dev.preauditai.com/v0'
}

export const endpoints = {
  get core () {
    return baseUrls.core
  },
  get soc () {
    return baseUrls.soc
  },
  get aws () {
    return baseUrls.aws
  },
  // get bibot () {
  //   return baseUrls.bibot
  // },
  get fedRampMod () {
    return baseUrls.fedrampmod
  },
  get gdpr () {
    return baseUrls.gdpr
  },
  get hipaa () {
    return baseUrls.hipaa
  },
  get pciDss () {
    return baseUrls.pciDss
  }
} as const

export enum domain {
  app = '/app',
  core = '/core',
  stripe = '/stripe',
  bibot = '/bibot',
  soc = '/soc',
  aws = '/aws',
  fedRampMod = '/fed-ramp-mod',
  gdpr = '/gdpr',
  hipaa = '/hipaa',
  pciDss = '/pci-dss',
  stateRamp = '/state-ramp',
  subscriptions = '/subscriptions',
  assessment = '/assessment'
}

export enum resources {
  contact = '/contact',
  form = '/form',
  usr = '/usr',
  register = '/register',
  verifyEmail = '/verify-email',
  resetPwd = '/reset-pwd',
  verify = '/verify',
  lm = '/lm',
  log = '/logs',
  metrics = '/metrics',
  soc = '/soc',
  assessment = '/assessment',
  submitAnswer = '/submit-answer',
  createAssessment = '/create-assessment',
  startAssessment = '/start-assessment',
  pauseAssessment = '/pause',
  restartAssessment = '/restart',
  closeAssessment = '/close-assessment',
  deleteAssessment = '/delete-assessment',
  archiveAssessment = '/archive-assessment',
  loadQuestions = '/load-questions',
  createTeam = '/create-team',
  team = '/team',
  all = '/all',
  reportsPath = '/reports',
  excel = '/excel',
  json = '/json',
  onDemand = '/on-demand',
  review = '/review',
  uploadPolicy = '/upload-policy',
  uploadTechScan = '/upload-tech-scan',
  executiveSummary = '/executive-summary',
  techScanRoleArn = '/tech-scan-role-arn',
  q = '/q',
  localQ = '/local-q',
  timedOutLocalQ = '/t-local-q',
  stripeSession = '/session',
  getSubscription = '/get-subscription',
  ampConfig = '/amp-config'
}
