import React from 'react'
import {
  LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend
} from 'recharts'
import type { RawPredictedDataDT, PredictedFutureFindingsDT } from 'types'

interface TooltipDataItem {
  name: string
  value: string | number
  color?: string
}

interface CustomTooltipProps {
  active?: boolean
  payload?: TooltipDataItem[]
  label?: string | number
}

const formatDate = (unixTimestamp: string | number | Date) => {
  const date = new Date(unixTimestamp)
  return date.toLocaleString('en-US', {
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: '2-digit',
    hour12: true
  })
}

const CustomTooltip: React.FC<CustomTooltipProps> = ({ active, payload, label }) => {
  if (active && payload?.length) {
    return (
        <div className="custom-tooltip" style={{ backgroundColor: '#fff', padding: '5px', border: '1px solid #ccc' }}>
          <p className="label">{`Date: ${formatDate(Number(label))}`}</p>
          {payload.map((entry, index) => (
            <p key={`item-${index}`} style={{ color: entry.color }}>{`${entry.name}: ${entry.value}`}</p>
          ))}
        </div>
    )
  }

  return null
}
interface PredictedFutureProps {
  data: PredictedFutureFindingsDT[]
  rawPredictionData: RawPredictedDataDT
}
const PredictedFuture: React.FC<PredictedFutureProps> = ({ data, rawPredictionData }) => {
  return (
<div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', fontFamily: 'Arial, sans-serif', margin: '20px' }}>
{
      !rawPredictionData.valid_predicted_value &&
      <div style={{ marginTop: '20px', fontSize: '14px', color: 'green' }}>
    <strong>BiBot Prediction:</strong>
    <span>{rawPredictionData.predicted_value}</span>
</div>
    }
    <LineChart
    style={{ width: '100%' }}
        width={600}
        height={300}
        data={data}
        margin={{
          top: 5, right: 10, left: 10, bottom: 5
        }}
    >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="date" tickFormatter={formatDate} />
        <YAxis />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Line type="monotone" dataKey="actual" stroke="#8884d8" name="Actual Data" />
        <Line type="monotone" dataKey="predicted" stroke="#82ca9d" strokeDasharray="5 5" name="Predicted Data" />
    </LineChart>

    <p style={{ marginTop: '20px', fontSize: '14px', color: '#666' }}>
        <strong>Disclaimer:</strong> Please note that these predictions are based on our trained models and the actual outcomes may vary based on a multitude of factors not captured in the historical data. This model does not account for potential interventions that could improve security measures or other external factors that could influence the number of failed checks. It&apos;s essential to use this information as one of several tools for planning security measures and not as an absolute forecast.
    </p>
</div>

  )
}

export { PredictedFuture }
